<template>
  <div class="visits">
    <div class="visits__months">
      <LessonStatistic :statistic="prevMonthStat" />
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downMonth"
        ></vs-button>
      </div>
      <div class="month-name">{{ current.name }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upMonth"
        ></vs-button>
      </div>
    </div>
    <AttendanceTable :data="data" :tablekey="group.id" :isStudentTable="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AttendanceTable from '@/components/visits/attendanceTable'
import getSchoolYearMonths from '@/plugins/schoolYears'
import LessonStatistic from '@/components/lessons/lessonMonthStat'

export default {
  name: 'visits',
  props: ['student', 'group'],
  data() {
    return {
      data: {},
      pages: [],
      current: {},
      today: new Date(),
      prevMonthStat: {},
    }
  },
  components: { AttendanceTable, LessonStatistic },
  computed: {
    ...mapState({
      lessons: state => state.lessons.lessons,
      ordersByStudent: state => state.orders.ordersByStudent,
    }),
    orders: function() {
      return this.ordersByStudent[this.student.id]
        ? this.ordersByStudent[this.student.id][this.group.id]
        : []
    },
    currentPrice: function() {
      if (!this.$can('manage', 'price')) return null
      if (this.current.monthObject && this.orders) {
        const curMonth = this.current.monthObject.month() + 1
        const curYear = this.current.monthObject.year()
        const order = this.orders.find(o => {
          if (o.month == curMonth && o.year == curYear) return o
        })
        if (order && order.price) return order.price
      }
      return this.group.group_type.price
    },
  },
  methods: {
    getAttendancePages: function() {
      let months = getSchoolYearMonths(this.group)
      this.pages = months.map((m, index) => {
        const monthObject = this.$moment(m.month, 'YYYY-MM')
        const page = {
          name: m.name,
          index,
          current:
            monthObject.month() == this.today.getMonth() &&
            monthObject.year() == this.today.getFullYear(),
          month: m.current,
          monthObject,
        }
        if (page.current) this.current = page
        return page
      })
      if (typeof this.current.index == 'undefined') {
        const schoolYearStartDate = new Date(
          this.group.schoolyear.date_start_unix * 1000
        )
        if (schoolYearStartDate.getMonth() + 1 == this.pages[0].month) {
          this.current = this.pages[0]
        } else {
          this.current = this.pages[this.pages.length - 1]
        }
      }
    },
    getAttendanceLessons: function() {
      let lessons = [...this.lessons]
      lessons = this.lessons.filter(
        l =>
          new Date(l.lesson_time * 1000).getMonth() + 1 == this.current.month &&
          l.group.id == this.group.id
      )

      const data = {
        thead: [
          {
            value: '',
          },
        ],
        rows: [
          {
            rowId: this.student.id,
            columns: [
              {
                value: this.student.name,
                link_to: {
                  name: 'Student',
                  params: { student_id: this.student.id },
                },
              },
            ],
          },
        ],
      }
      let countLessons = 0
      let partedLessonCount = 0
      lessons.forEach(l => {
        let status = null
        if (l.cancelled) status = 'cancelled'

        data.thead.push({
          value: new Date(l.lesson_time * 1000).getDate(),
          link_to: {
            name: 'Lessons',
            params: { lesson_id: l.id },
          },
          status,
          icon: l.date_end ? 'checked' : null,
        })

        data.rows.forEach(r => {
          const studentInLesson = l.students.find(s => s.id == r.rowId)
          if (studentInLesson) {
            switch (studentInLesson.exist) {
              case 'б':
                status = 'wrong'
                break
              case '0':
                status = 'empty'
                break
              case 'н':
                status = 'danger'
                countLessons++
                break
              case 'ч':
                status = 'warn'
                partedLessonCount++
                break
              case '1':
                status = null
                countLessons++
                break
              default:
                status = null
            }
            r.columns.push({
              value: studentInLesson.exist || '',
              status,
              edited: this.$can('manage', 'student_missings') ? true : false,
              itemId: l.id,
              rowId: studentInLesson.id,
              columnId: studentInLesson.link_id,
              columnType: 'exist',
              comment: studentInLesson.comment,
              surprises: studentInLesson.surprises,
              is_trial: studentInLesson.is_trial,
            })
          } else {
            status = 'disabled'
            r.columns.push({
              value: '',
              status,
            })
          }
        })
      })
      if (this.$can('manage', 'price')) {
        data.thead.push(
          {
            value: 'count',
          },
          {
            value: 'partly counted',
          },
          {
            value: 'discount',
          },
          {
            value: 'price',
          },
          {
            value: 'amount',
          }
        )
        const price = this.currentPrice
        let discount = 0
        if (this.group.discount && this.group.discount.length) {
          const monthDiscount = this.group.discount.find(
            d => d.month == this.current.month
          )
          if (
            monthDiscount &&
            monthDiscount.discount &&
            monthDiscount.discount !== 'null'
          )
            discount = parseInt(monthDiscount.discount, 10)
        }
        let amount = ((100 - discount) * price * countLessons) / 100
        if (partedLessonCount) {
          amount += price * partedLessonCount * 0.25
        }

        data.rows[0].columns.push(
          {
            value: countLessons,
          },
          {
            value: partedLessonCount,
          },
          {
            value: discount ? `${discount} %` : '-',
          },
          {
            value: price,
          },
          {
            value: amount,
          }
        )
      }

      this.data = data
    },
    getStatOfPrevMonth: function() {
      const prevMonth = this.pages[this.current.index - 1]
      if (prevMonth) {
        const prevMonthLessons = this.lessons.filter(
          l =>
            new Date(l.lesson_time * 1000).getMonth() + 1 == prevMonth.month &&
            l.group.id == this.group.id
        )
        let allCount = prevMonthLessons.length,
          cancelledCount = 0,
          doneCount = 0

        cancelledCount = prevMonthLessons.reduce(
          (a, b) => (b.cancelled ? a + 1 : a),
          0
        )
        doneCount = prevMonthLessons.reduce(
          (a, b) => (b.date_end ? a + 1 : a),
          0
        )
        this.prevMonthStat = {
          monthName: prevMonth.name,
          allCount,
          doneCount,
          cancelledCount,
        }
      } else {
        this.prevMonthStat = {}
      }
    },
    downMonth: function() {
      const index = this.current.index
      if (index > 0) {
        this.current = this.pages[index - 1]
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      }
    },
    upMonth: function() {
      const index = this.current.index
      if (index + 1 < this.pages.length) {
        this.current = this.pages[index + 1]
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      }
    },
    setPage() {
      this.getAttendancePages()
      this.getAttendanceLessons()
      this.getStatOfPrevMonth()
    },
  },
  watch: {
    student: function(newV) {
      if (newV) {
        this.setPage()
      }
    },
    lessons: function(newV) {
      if (newV) {
        this.getAttendanceLessons()
        this.getStatOfPrevMonth()
      }
    },
    // currentPrice: function(newV) {
    //   if (newV) {
    //     this.setPage()
    //   }
    // },
  },
  created() {
    this.setPage()
  },
}
</script>
