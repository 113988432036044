<template>
  <div class="student">
    <div class="status-bar">
      <div class="status-bar__back-button">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          @click="backHandler"
          title="back to group list"
        ></vs-button>
      </div>
      <h2 class="status-bar__title">
        <vs-icon icon="person"></vs-icon>
        {{ student.name }}
      </h2>
      <vs-chip
        :color="
          status.value == 1
            ? 'primary'
            : status.value == 6
            ? '#5b3cc4'
            : 'warning'
        "
        v-if="status.value == 1 || status.value == 2 || status.value == 6"
        >{{ status.key.toUpperCase() }}</vs-chip
      >
      <div class="status-bar__manage-buttons" v-if="$can('manage', 'student')">
        <vs-button
          icon="edit"
          class="status-bar__edit-button"
          type="flat"
          color="dark"
          @click="popupActive = true"
          >Edit</vs-button
        >
      </div>
      <div class="status-bar__manage-buttons">
        <vs-button
          icon="star"
          class="status-bar__edit-button"
          type="flat"
          color="dark"
          @click="surprisePopupActive = true"
          >Add surprise</vs-button
        >
      </div>
      <div class="balance" v-if="$can('manage', 'payments') && student.balance">
        <student-balance :student="student" />
      </div>
    </div>
    <div class="view-content">
      <Split>
        <SplitArea :size="leftSize">
          <tasks-wrapper place_type="student" :place_id="parseInt(id, 10)" />
          <div class="student-schoolyears">
            <vs-select width="300px" v-model="selectedYearId">
              <vs-select-item
                :key="year.id"
                :value="year.id"
                :text="year.name"
                v-for="year in years"
              />
            </vs-select>
          </div>
          <vs-tabs>
            <vs-tab :label="`Groups (${studentGroups.length})`" icon="people">
              <div v-if="$can('manage', 'student')">
                <vs-button
                  icon="add"
                  type="flat"
                  @click="addToGroupFormShow = true"
                  class="add-to-group-button manage-student-button"
                >
                  Add to group
                </vs-button>
              </div>
              <div v-if="student && studentGroups">
                <div
                  class="student-groups collapsible"
                  v-for="group in studentGroups"
                  :key="group.id"
                >
                  <student-group-component
                    :group="group"
                    :student="student"
                    @editGroupHandle="editGroupHandle"
                    @removeStudent="removeStudentHandle"
                    @moveStudent="moveStudentHandle"
                    @stayHereGroup="stayHereGroupHandle"
                  />
                </div>
              </div>
            </vs-tab>
            <vs-tab
              :label="`Tutor (${studentTutorGroups.length})`"
              icon="portrait"
            >
              <div>
                <tutor-wrapper
                  :schoolYearId="selectedYearId"
                  :groups="studentTutorGroups"
                  @editGroupHandle="editGroupHandle"
                  @removeStudent="removeStudentHandle"
                  @moveStudent="moveStudentHandle"
                />
              </div>
            </vs-tab>
            <vs-tab :label="`Books`" icon="import_contacts">
              <student-books
                :student_id="$route.params.student_id"
                :schoolyear="selectedYearId"
              />
            </vs-tab>
          </vs-tabs>
        </SplitArea>
        <SplitArea :size="rightSize">
          <vs-button
            type="flat"
            icon="fullscreen"
            color="dark"
            title="Expand panel"
            @click="rightSize = 100 - rightSize"
            size="small"
            class="expand-right-panel"
          ></vs-button>
          <vs-tabs>
            <vs-tab label="Comments" icon="comment">
              <div class="con-tab-ejemplo">
                <Comments
                  :itemId="$route.params.student_id"
                  itemType="Students"
                  :key="commentReload"
                />
              </div>
            </vs-tab>
            <vs-tab label="Tests" icon="colorize">
              <student-results :student_id="$route.params.student_id" />
            </vs-tab>
            <vs-tab label="Surprises" color="red" icon="star">
              <surprisers-wrapper />
            </vs-tab>
            <vs-tab label="Info" color="red" icon="info">
              <student-info />
            </vs-tab>
          </vs-tabs>
        </SplitArea>
      </Split>
    </div>
    <vs-popup
      class="form-popup students__popup"
      classContent="students__popup-content"
      title="Student"
      :active.sync="popupActive"
    >
      <StudentForm
        v-if="popupActive"
        :groupsList="groups"
        :student="student"
        @closePopup="closePopup"
      />
    </vs-popup>
    <vs-popup
      class="form-popup students__edit-group-popup"
      classContent="students__popup-content"
      title="Student"
      :active.sync="editGroupPopupActive"
    >
      <EditGroupExpandComponent
        v-if="editGroupPopupActive"
        :student="student"
        :group="editedGroup"
        :schoolyear="currentYear"
        @closePopup="closePopup"
      />
    </vs-popup>
    <div class="popup-edit">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        title="Add surprise"
        :active.sync="surprisePopupActive"
        v-if="surprisePopupActive"
      >
        <surprise-form
          :students="[student]"
          :selectedStudentId="student.id"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
    <div class="popup-add-to-group">
      <vs-popup
        class="form-popup add-to-group__popup"
        classContent="add-to-group__popup-content"
        title="Add add to group"
        :active.sync="addToGroupFormShow"
        v-if="addToGroupFormShow"
      >
        <AddStudentToGroupForm
          v-if="addToGroupFormShow"
          :student="student"
          @closeForm="closeAddGroupFormHandle"
        />
      </vs-popup>
    </div>
    <div class="prompt">
      <vs-prompt
        color="danger"
        @accept="acceptRemove"
        :active.sync="removePromptActive"
        :is-valid="!!removeComment"
        v-if="removePromptActive"
      >
        <div>
          <p>Do you really want to remove student from group?</p>
          <br />
          <p>Please, write a comment with reason:</p>
          <vs-textarea
            v-model="removeComment"
            counter="250"
            class="reason-comment"
            autofocus
          />
        </div>
      </vs-prompt>
    </div>
    <div class="prompt">
      <vs-prompt
        @accept="acceptMove"
        :active.sync="movePromptActive"
        :is-valid="!!move.newGroupId"
        title="Move student?"
        color="primary"
      >
        <div class="field">
          <vs-select
            autocomplete
            class="selectExample"
            label="New group"
            v-model="move.newGroupId"
            autofocus
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in groups"
            />
          </vs-select>
        </div>
        <div class="field">
          <label for="">Move date</label>
          <v-date-picker v-model="move.moveDate"> </v-date-picker>
        </div>
        <div class="field">
          <vs-checkbox v-model="move.withBooksFlag">
            Move with books and tests results
          </vs-checkbox>
        </div>
        <div class="field">
          <vs-checkbox v-model="move.is_trial">
            Move as trial
          </vs-checkbox>
        </div>
        <div class="field">
          <label for="">Comment</label>
          <vs-textarea
            v-model="move.comment"
            counter="250"
            class="reason-comment"
          />
        </div>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StudentForm from '@/components/students/studentForm.vue'
import Comments from '@/components/comments/comments.vue'
import EditGroupExpandComponent from '@/components/students/editGroupExpandComponent'
import AddStudentToGroupForm from '@/components/students/addStudentToGroupForm'
import StudentBooks from '../components/books/students/studentBooks.vue'
import StudentResults from '../components/students/results/studentResults.vue'
import StudentBalance from '../components/students/studentBalance.vue'
import StudentGroupComponent from '../components/students/studentGroupComponent.vue'
import TutorWrapper from '../components/students/tutor/wrapper.vue'
import StudentInfo from '../components/students/studentInfo.vue'
import SurprisersWrapper from '../components/students/surprisers/surprisersWrapper.vue'
import SurpriseForm from '../components/students/surprisers/surpriseForm.vue'
import TasksWrapper from '../components/tasks/tasksWrapper.vue'
import StudentStatuses from '@/components/common/statuses/studentStatuses'

export default {
  name: 'StudentView',
  title: 'SE-Student',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      // clear your previously populated search results.
      // re-populate search results
      vm.prevUrl = from.fullPath
    })
  },
  data() {
    return {
      popupActive: false,
      editGroupPopupActive: false,
      addToGroupFormShow: false,
      id: this.$route.params.student_id,
      selectedYearId: null,
      editedGroup: {},
      rightSize: 30,
      groupOpened: true,
      sortGroupKey: 'insert_date',
      surprisePopupActive: false,
      removePromptActive: false,
      movePromptActive: false,
      removeComment: null,
      removeFromGroup: null,
      moveFromGroup: null,
      commentReload: 0,
      move: {
        moveDate: new Date(),
        newGroupId: null,
        comment: '',
        withBooksFlag: true,
        is_trial: false,
      },
      prevUrl: null,
    }
  },
  components: {
    StudentForm,
    Comments,
    EditGroupExpandComponent,
    AddStudentToGroupForm,
    StudentBooks,
    StudentResults,
    StudentBalance,
    StudentGroupComponent,
    TutorWrapper,
    StudentInfo,
    SurprisersWrapper,
    SurpriseForm,
    TasksWrapper,
  },
  computed: {
    ...mapState({
      student: state => state.students.singleStudent,
      groups: state => state.groups.groups,
      student_balance: state => state.students.balance,
      tutorLessons: state => state.lessons.tutorLessonsByStudent,
      books: state => state.books.books,
      years: state => state.years.years,
      currentYear: state => state.years.currentSchoolYear,
    }),
    leftSize: function() {
      return 100 - this.rightSize
    },
    studentGroups: function() {
      // const { sortGroupKey } = this;
      if (!this.student.groups) return []
      const groupsList = this.student.groups.filter(g => !g.is_tutor)
      const excludedGroups = groupsList
        .filter(g => !g.is_active)
        .sort((a, b) => a.group_type.weight - b.group_type.weight)
      const groups = groupsList
        .filter(g => g.is_active)
        .sort((a, b) => a.group_type.weight - b.group_type.weight)
      return [...groups, ...excludedGroups]
    },
    studentTutorGroups: function() {
      // const { sortGroupKey } = this;
      if (!this.student.groups) return []
      const groupsList = this.student.groups.filter(g => g.is_tutor)
      const excludedGroups = groupsList
        .filter(g => !g.is_active)
        .sort((a, b) => a.group_type.weight - b.group_type.weight)
      const groups = groupsList
        .filter(g => g.is_active)
        .sort((a, b) => a.group_type.weight - b.group_type.weight)
      return [...groups, ...excludedGroups]
    },
    status: function() {
      let statusKey = Object.keys(StudentStatuses).find(
        s => StudentStatuses[s] === this.student.status
      )
      return {
        key: statusKey,
        value: this.student.status,
      }
    },
  },
  methods: {
    // getGroups: function () {
    //   let years = {};
    //   const { sortGroupKey } = this;

    //   this.student.groups.forEach((group) => {
    //     years[group.schoolyear.id] = group.schoolyear;
    //   });
    //   if (Object.values(years).length == 1) {
    //     this.selectedYearId = Object.keys(years)[0];
    //   }
    //   this.studentGroups = this.student.groups
    //     .filter((g) => g.schoolyear.id == this.selectedYearId)
    //     .sort((a, b) => {
    //       if (a[sortGroupKey] < b[sortGroupKey]) return 1;
    //       if (
    //         a[sortGroupKey] > b[sortGroupKey] ||
    //         a[sortGroupKey] == b[sortGroupKey]
    //       )
    //         return -1;
    //     });
    //   this.schoolYears = years;
    // },
    closePopup: function() {
      this.popupActive = false
      this.editGroupPopupActive = false
      this.editedGroup = {}
      this.surprisePopupActive = false
      this.getStudent()
    },
    editGroupHandle: function(group) {
      this.editedGroup = group
      this.editGroupPopupActive = true
    },
    removeStudentHandle: function(group) {
      this.removeFromGroup = group
      if (group.is_trial) {
        this.removeComment = `Was on trial in group ${this.removeFromGroup.name}`
      }
      this.removePromptActive = true
    },
    moveStudentHandle: function(group) {
      this.moveFromGroup = group
      if (group.is_trial) {
        this.move.comment = `Was on trial in group ${this.moveFromGroup.name}`
      }
      this.movePromptActive = true
    },
    acceptRemove: function() {
      let comment = `<b>Remove student from Group ${this.removeFromGroup.name}.</b><p>Reason: ${this.removeComment}</p>`
      this.$store
        .dispatch('students/removeStudentFromGroupAction', {
          student_id: this.student.id,
          group_id: this.removeFromGroup.id,
          comment,
        })
        .then(async () => {
          ;(this.removePromptActive = false),
            (this.removeComment = null),
            (this.removeFromGroup = null)
          await this.getStudent()
          this.commentReload++
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    acceptMove: function() {
      this.$vs.loading()
      let { moveDate, newGroupId, comment, withBooksFlag, is_trial } = this.move

      let newGroup = this.groups.find(g => g.id == newGroupId)
      comment = `<b>Move student from Group ${this.moveFromGroup.name} to  ${
        newGroup.name
      }.</b><p>Reason: ${comment || '-'}</p>`

      const data = {
        move_date: moveDate.getTime() / 1000,
        new_group_id: newGroupId,
        with_books: withBooksFlag,
        is_trial,
        comment,
      }

      this.$store
        .dispatch('students/moveStudentToGroupAction', {
          studentId: this.student.id,
          groupId: this.moveFromGroup.id,
          data,
        })
        .then(async () => {
          this.$vs.loading.close()
          this.movePromptActive = false
          this.moveFromGroup = null
          this.move = {
            moveDate: new Date(),
            newGroupId: null,
            comment: '',
            withBooksFlag: true,
          }

          await this.getStudent()
          this.commentReload++
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    stayHereGroupHandle: function(group) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Stay student in group ${group.name}`,
        text: `Оставить ученика в группе ${group.name}? Из других групп с пробными занятиями он будет удален!`,
        accept: async () => {
          await this.$store.dispatch('students/stayStudentInGroupAction', {
            id: this.student.id,
            group_id: group.id,
          })
          window.location.reload()
        },
      })
    },
    closeAddGroupFormHandle: function() {
      this.addToGroupFormShow = false
    },
    getStudent: async function() {
      this.$vs.loading()
      const { student_id } = this.$route.params
      await this.$store
        .dispatch('students/getSingleStudentAction', {
          id: student_id,
          schoolyear_id: this.selectedYearId,
        })
        .then(async () => {
          let groups_ids = [
            ...this.studentGroups.map(g => g.id),
            ...this.studentTutorGroups.map(g => g.id),
          ]
          if (groups_ids.length) {
            await this.$store.dispatch('lessons/getLessonsByGroupsAction', {
              group_id: groups_ids.join(','),
              student_id,
            })
          }

          const data = {
            schoolyear_id: this.selectedYearId,
            exclude_students: true,
          }
          await this.$store.dispatch('groups/getGroupsAction', { data })
          if (this.$can('manage', 'payments')) {
            await this.$store.dispatch(
              'orders/getOrdersGroupsByStudentAction',
              {
                student: student_id,
                groups: groups_ids,
                ...data,
              }
            )
          }

          await this.$store.dispatch('lessons/getTutorLessonsByStudentAction', {
            student_id,
            schoolyear_id: this.selectedYearId,
          })
          await this.$store.dispatch('books/getStudentBooksAction', {
            student_id,
            schoolyear_id: this.selectedYearId,
          })
          this.$vs.loading.close()
        })
    },
    backHandler: function() {
      const { prevUrl } = this
      let to = '/students'
      if (prevUrl) {
        if (
          prevUrl.includes('groups') ||
          prevUrl.includes('lessons') ||
          prevUrl.includes('students')
        ) {
          to = prevUrl
        }
      }
      this.$router.push(to)
    },
  },
  created: async function() {
    if (!this.years.length) {
      await this.$store.dispatch('years/getYearsAction')
    }
    this.selectedYearId = this.currentYear.id

    // this.getStudent();
  },
  watch: {
    selectedYearId: function(newV, oldV) {
      if (oldV != newV) {
        this.getStudent()
      }
    },
    currentYear: function(newV) {
      this.selectedYearId = newV.id
    },
  },
  beforeDestroy() {
    this.$store.dispatch('students/clearAction')
    this.$store.dispatch('books/clearStudentBooksAction')
  },
}
</script>

<style lang="scss">
.students__popup {
  .vs-popup {
    width: 75%;
  }
}
.student-schoolyears {
  margin: 0 15px;
  position: absolute;
  right: 0;
  z-index: 120;
}
.student-groups {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;

  .student-groups__manage {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin-right: 15px;
    }
    .vs-button {
      float: none !important;
      i {
        // font-size: 12px;
      }
    }
    .group-link-date {
      font-size: 0.8em;
      padding: 3px 10px;
      box-shadow: 0 0 1px 2px #eee;
      border-radius: 10px;
    }
  }
}
.status-bar {
  .balance {
    flex: 1;
    margin-left: 15px;
    line-height: 2em;
    & > div {
      margin: 0 7px;
      display: inline-block;
      span {
        font-weight: bold;
      }
    }
  }
}
.manage-student-button {
  float: none !important;
}
</style>
