<template>
  <div class="result__form">
    <vs-row
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="space-around"
      vs-w="12"
    >
      <vs-col vs-w="5">
        <div class="field result__type">
          <vs-select label="Test type" v-model="resultType">
            <vs-select-item value="TestsResult" text="Test" />
            <vs-select-item value="TestsIncomeResult" text="Entry test" />
          </vs-select>
        </div>
        <div v-if="resultType == 'TestsIncomeResult'">
          <div class="field">
            <label>Name</label>
            <vs-input size="large" v-model="name"></vs-input>
          </div>
          <div class="field">
            <label>Oral value</label>
            <vs-input size="large" v-model="oral_value"></vs-input>
          </div>
          <div class="field">
            <label>Written value</label>
            <vs-input size="large" v-model="written_value"></vs-input>
          </div>
          <div class="field">
            <label>Comment</label>
            <vs-input size="large" v-model="comment"></vs-input>
          </div>
        </div>
        <div v-else>
          <div v-if="!result">
            <div class="field">
              <vs-select label="Group" v-model="group_id">
                <vs-select-item
                  :key="group.id"
                  :value="group.id"
                  :text="group.name"
                  v-for="group in student.groups"
                />
              </vs-select>
            </div>
            <div class="field">
              <vs-select label="Test" v-model="test_id">
                <vs-select-item
                  :key="test.id"
                  :value="test.id"
                  :text="`${test.name} (${test.book.name})`"
                  v-for="test in tests"
                />
              </vs-select>
            </div>
          </div>
          <div class="field">
            <label>Result value</label>
            <vs-input
              size="large"
              v-model="value"
              :description-text="`max value: ${testOfResult.max_value || '-'}`"
              :danger="$v.value && !$v.value.between"
              :danger-text="
                `value must bee between 0 and ${testOfResult.max_value || '-'}`
              "
            ></vs-input>
          </div>
        </div>
      </vs-col>
      <vs-col vs-w="6" vs-offset="1">
        <div class="insert-date-calendar field">
          <label>Enter test date</label>
          <v-date-picker v-model="test_time" />
        </div>
      </vs-col>
    </vs-row>

    <div class="save-popup-button">
      <vs-button
        icon="save"
        @click="saveResult"
        :disabled="$v.value && !$v.value.between"
        >Save</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { between } from 'vuelidate/lib/validators'
export default {
  name: 'ResultForm',
  props: ['result', 'student'],
  computed: {
    ...mapState({
      singleResult: state => state.results.singleResult,
      tests: state => state.books.tests,
    }),
    testOfResult: function() {
      if (this.result && this.result.id) return this.result.test
      else if (this.test_id && this.tests.length)
        return this.tests.find(t => t.id == this.test_id)
      return {}
    },
  },
  data() {
    let props = {
      id: null,
      name: '',
      value: 0,
      oral_value: 0,
      written_value: 0,
      comment: null,
      test_time: new Date(),
      book_id: null,
      test_id: null,
      resultType: this.result?.type || 'TestsResult',
      group_id: null,
    }
    if (this.result && this.result.id) {
      props = Object.assign(props, this.setDataFromResult())
    }

    if (this.student && this.student.id && this.student.groups.length == 1) {
      let group_id = this.student.groups[0].id
      props.group_id = group_id
      this.getTestsByGroup(group_id)
    }

    if (this.student && this.student.id) props.student_id = this.student.id
    return props
  },
  validations() {
    if (this.testOfResult.max_value) {
      return {
        value: {
          between: between(0, this.testOfResult.max_value),
        },
      }
    } else {
      return {}
    }
  },
  methods: {
    saveResult: function() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      let { id } = this

      if (id) this.updateResult()
      else this.addResult()
    },
    setDataFromResult: function() {
      const r = Object.assign({}, this.result)
      r.test_time = new Date(r.test_time * 1000)

      const {
        id,
        value,
        oral_value,
        written_value,
        test_time,
        test,
        comment,
      } = r
      return {
        id,
        oral_value: oral_value || 0,
        written_value: written_value || 0,
        test_time,
        test,
        name: test.name,
        value: value || 0,
        comment,
      }
    },
    updateResult: function() {
      let {
        id,
        value,
        oral_value,
        written_value,
        name,
        test_time,
        test,
        comment,
      } = this
      if (typeof value === 'string') {
        value = parseFloat(value.replace(',', '.'))
      }
      let data = {
        test_time: test_time.getTime() / 1000,
      }
      if (this.resultType == 'TestsResult') {
        Object.assign(data, {
          value,
          result_id: id,
          test_id: test.id,
        })
        this.$store
          .dispatch('results/setSingleResultAction', { data })
          .then(response => {
            this.successResponse(response)
          })
          .catch(e => {
            this.errorResponse(e)
          })
      } else if (this.resultType == 'TestsIncomeResult') {
        Object.assign(data, {
          oral_value,
          written_value,
          name,
          lesson_id: this.result.lesson ? this.result.lesson.id : null,
          student_id: this.result.student.id,
          comment,
          id,
        })
        this.$store
          .dispatch('results/setIncomeTestResultAction', { data: [data] })
          .then(response => {
            this.successResponse(response)
          })
          .catch(e => {
            this.errorResponse(e)
          })
      }
    },

    addResult: function() {
      let {
        value,
        test_time,
        test_id,
        book_id,
        group_id,
        student_id,
        resultType,
        oral_value,
        written_value,
        name,
        comment,
      } = this
      if (typeof value === 'string') {
        value = parseFloat(value.replace(',', '.'))
      }
      let data
      if (resultType == 'TestsResult') {
        data = {
          test_time: test_time.getTime() / 1000,
          value,
          test_id,
          book_id,
          group_id,
          student_id,
        }
        this.$store
          .dispatch('results/addSingleResultAction', { data })
          .then(response => {
            this.successResponse(response)
          })
          .catch(e => {
            this.errorResponse(e)
          })
      } else {
        data = {
          test_time: test_time.getTime() / 1000,
          value,
          oral_value,
          written_value,
          name,
          student_id,
          comment,
        }
        this.$store
          .dispatch('results/addIncomeTestResultAction', { data: [data] })
          .then(response => {
            this.successResponse(response)
          })
          .catch(e => {
            this.errorResponse(e)
          })
      }
    },
    successResponse: function(response) {
      this.$vs.notify({
        title: 'Result saved',
        text: `Result saved`,
        color: 'success',
        position: 'top-right',
      })
      this.$emit('closePopup', response)
    },
    errorResponse: function(e) {
      this.$vs.notify({
        title: 'Error',
        text: `${e.message}`,
        color: 'danger',
        position: 'top-right',
      })
    },
    getTestsByGroup: function(group_id) {
      const student_id = this.student.id
      this.$store.dispatch('books/getTestsAction', { group_id, student_id })
    },
  },
  watch: {
    student: function(newV) {
      if (newV.id && newV.groups.length == 1) this.group_id = newV.groups[0].id
    },
    group_id: function(newV, oldV) {
      if (oldV != newV) this.getTestsByGroup(newV)
    },
    test_id: function(newV) {
      const test = this.tests.find(t => t.id == newV)
      this.book_id = test.book.id
    },
    result: function(newV) {
      if (newV) {
        const result = this.setDataFromResult()
        this.id = result.id
        this.value = result.value
        this.test_time = result.test_time
        this.test = result.test
      }
    },
  },
}
</script>

<style lang="scss">
.result__form {
  .vs-input {
    width: 100%;
    margin-bottom: 25px;
  }
  .con-select {
    width: 100%;
  }
}
</style>
