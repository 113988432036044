<template>
  <div class="testing-grade">
    <TestingTable :items="data" :withShift="true" :withGrade="true" />
  </div>
</template>

<script>
import TestingTable from './testingTable.vue'

export default {
  props: ['data'],
  components: { TestingTable },
}
</script>

<style lang="scss"></style>
