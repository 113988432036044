<template>
  <div class="visits">
    <div class="visits__months">
      <LessonStatistic :statistic="prevMonthStat" />
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downMonth"
        ></vs-button>
      </div>
      <div class="month-name">{{ current ? current.name : "empty" }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upMonth"
        ></vs-button>
      </div>
    </div>
    <AttendanceTable
      :data="data"
      :tablekey="student.id"
      :isStudentTable="true"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AttendanceTable from "@/components/visits/attendanceTable";
import LessonStatistic from "@/components/lessons/lessonMonthStat";
export default {
  data() {
    return {
      data: {},
      pages: [],
      current: {},
      today: new Date(),
      prevMonthStat: {},
    };
  },
  components: {
    AttendanceTable,
    LessonStatistic,
  },
  computed: {
    ...mapState({
      student: (state) => state.students.singleStudent,
      lessons: (state) => state.lessons.tutorLessonsByStudent,
    }),
    months: function () {
      const format = "YYYY-MM";
      if (!this.lessons.length) return [];
      let months = this.lessons
        .reduce((list, lesson) => {
          const lessonTime = this.$moment(lesson.lesson_time * 1000);
          const month = lessonTime.startOf("month");
          if (!list.find((m) => m.format(format) == month.format(format))) {
            list.push(month);
          }
          return list;
        }, [])
        .map((m, index) => {
          return {
            name: m.format("MMMM"),
            current: parseInt(m.format("M"), 10),
            index,
          };
        });
      return months.reverse();
    },
  },
  methods: {
    getAttendancePages: function () {
      let months = this.months;
      this.pages = months.map((m, index) => {
        const page = {
          name: m.name,
          index,
          current: m.current == this.today.getMonth() + 1,
          month: m.current,
        };
        if (page.current) this.current = page;
        return page;
      });
      if (!this.current.index) this.current = this.pages[this.pages.length - 1];
    },
    getAttendanceLessons: function () {
      let lessons = [...this.lessons];
      lessons = this.lessons
        .filter(
          (l) =>
            new Date(l.lesson_time * 1000).getMonth() + 1 == this.current.month,
        )
        .reverse();

      const data = {
        thead: [
          {
            value: "",
          },
        ],
        rows: [
          {
            rowId: this.student.id,
            columns: [
              {
                value: this.student.name,
                link_to: {
                  name: "Student",
                  params: { student_id: this.student.id },
                },
              },
            ],
          },
        ],
      };
      let countLessons = 0;
      let partedLessonCount = 0;
      let amount = 0;
      lessons.forEach((l) => {
        let status = null;
        if (l.cancelled) status = "cancelled";

        data.thead.push({
          value: new Date(l.lesson_time * 1000).getDate(),
          link_to: {
            name: "Lessons",
            params: { lesson_id: l.id },
          },
          status,
          icon: l.date_end ? "checked" : null,
        });
        const price = l.price || 0;

        data.rows.forEach((r) => {
          const studentInLesson = l.students.find((s) => s.id == r.rowId);
          if (studentInLesson) {
            switch (studentInLesson.exist) {
              case "б":
                status = "wrong";
                break;
              case "н":
                status = "danger";
                countLessons++;
                amount += price;
                break;
              case "ч":
                status = "warn";
                partedLessonCount++;
                amount += price * 0.25;
                break;
              case "1":
                status = null;
                countLessons++;
                amount += price;
                break;
              default:
                status = null;
            }
            r.columns.push({
              value: studentInLesson.exist || "",
              status,
              edited: this.$can("manage", "student_missings") ? true : false,
              itemId: l.id,
              rowId: studentInLesson.id,
              columnId: studentInLesson.link_id,
              columnType: "exist",
              comment: studentInLesson.comment,
            });
          } else {
            status = "disabled";
            r.columns.push({
              value: "",
              status,
            });
          }
        });
      });
      if (this.$can("manage", "price")) {
        data.thead.push(
          {
            value: "count",
          },
          {
            value: "partly counted",
          },
          {
            value: "amount",
          },
        );
        data.rows[0].columns.push(
          {
            value: countLessons,
          },
          {
            value: partedLessonCount,
          },
          {
            value: amount,
          },
        );
      }

      this.data = data;
    },
    getStatOfPrevMonth: function () {
      if (!this.lessons.length) return {};
      const prevMonth = this.pages[this.current.index - 1];
      if (prevMonth) {
        const prevMonthLessons = this.lessons.filter(
          (l) =>
            new Date(l.lesson_time * 1000).getMonth() + 1 == prevMonth.month,
        );
        let allCount = prevMonthLessons.length,
          cancelledCount = 0,
          doneCount = 0;

        cancelledCount = prevMonthLessons.reduce(
          (a, b) => (b.cancelled ? a + 1 : a),
          0,
        );
        doneCount = prevMonthLessons.reduce(
          (a, b) => (b.date_end ? a + 1 : a),
          0,
        );
        this.prevMonthStat = {
          monthName: prevMonth.name,
          allCount,
          doneCount,
          cancelledCount,
        };
      } else {
        this.prevMonthStat = {};
      }
    },
    downMonth: function () {
      if (!this.current) return;
      const index = this.current.index;
      if (index > 0) {
        this.current = this.pages[index - 1];
        this.getAttendanceLessons();
        this.getStatOfPrevMonth();
      }
    },
    upMonth: function () {
      if (!this.current) return;
      const index = this.current.index;
      if (index + 1 < this.pages.length) {
        this.current = this.pages[index + 1];
        this.getAttendanceLessons();
        this.getStatOfPrevMonth();
      }
    },
    setPage() {
      this.getAttendancePages();
      this.getAttendanceLessons();
      this.getStatOfPrevMonth();
    },
  },
  watch: {
    lessons: function (newV) {
      if (newV) {
        this.setPage();
      }
    },
  },
  created() {
    this.setPage();
  },
};
</script>

<style>
</style>