<template>
  <div class="students">
    <div class="students__add-button" v-if="$can('manage', 'student')">
      <vs-button icon="add" type="flat" @click="popupActive = true"
        >Add student</vs-button
      >
    </div>
    <vs-tabs v-model="activeTab" v-if="$can('manage', 'students')">
      <vs-tab
        :label="`Queue (${studentsCount.queue_count || 0})`"
        class="my"
        value="1"
        @click="tabClickHandler(1)"
      >
      </vs-tab>
      <vs-tab
        :label="`Testing (${studentsCount.testing_count || 0})`"
        value="2"
        @click="tabClickHandler(2)"
      >
      </vs-tab>
      <vs-tab
        :label="`Trial (${studentsCount.trial_count || 0})`"
        value="6"
        @click="tabClickHandler(6)"
      >
      </vs-tab>
      <vs-tab
        :label="`Active (${studentsCount.active_count || 0})`"
        value="3"
        @click="tabClickHandler(3)"
      >
      </vs-tab>
      <vs-tab
        :label="`All (${studentsCount.all_count || 0})`"
        value="4"
        @click="tabClickHandler(4)"
      >
      </vs-tab>
    </vs-tabs>
    <student-list-filter :existFilters="filters" @filterEvent="filterEvent" />
    <StudentList
      id="student-list-container"
      @editStudent="editStudent($event)"
      @deleteStudent="deleteStudent($event)"
      @studentToTesting="studentToTesting($event)"
      @studentToQueue="studentToQueue($event)"
      @studentToActive="studentToActive($event)"
      :statusID="status"
      :students="studentsData"
    />
    <vs-popup
      class="students__popup"
      classContent="students__popup-content"
      title="Student"
      :active.sync="popupActive"
      @close="closePopup"
      v-if="popupActive"
    >
      <StudentForm
        :student="selectedStudent"
        :groupsList="groups"
        :students="students"
        @updateStudents="afterUpdateStudent"
        @closePopup="closePopup"
      />
    </vs-popup>
    <vs-popup
      class="students-add-to-group__popup"
      classContent="students__popup-content"
      :title="'Add student to group' + (addAsTrial ? '(s) as trial' : '')"
      :active.sync="popupAddToGroupActive"
      @close="closePopup"
      v-if="popupAddToGroupActive"
    >
      <add-student-to-group-form
        :student="selectedStudent"
        :asTrial="addAsTrial"
        :isPopup="true"
        @closeForm="closePopup"
        @updateStudents="afterUpdateStudent"
      />
    </vs-popup>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'

import StudentList from '@/components/students/studentsList.vue'
import StudentForm from '@/components/students/studentForm.vue'
import { mapState } from 'vuex'
import StudentStatuses from '@/components/common/statuses/studentStatuses'
import AddStudentToGroupForm from '../components/students/addStudentToGroupForm.vue'
import StudentListFilter from '../components/students/studentListFilter.vue'

export default {
  title: 'SE-Students',

  data() {
    return {
      status: 0,
      popupActive: false,
      popupAddToGroupActive: false,
      selectedStudent: {},
      activeTab: this.$can('manage', 'students') ? 0 : 2,
      defaultTabHash: this.$can('manage', 'students') ? 'queue' : 'active',
      filters: {},
      statusID: 1,
      addAsTrial: false, // флаг для добавления ученика в пробное занятие
    }
  },
  computed: {
    ...mapState({
      students: state => state.students.students,
      studentsCount: state => state.students.studentsCount,
      groups: state => state.groups.groups,
      singleStudent: state => state.students.singleStudent,
      currentSchoolyear: state => state.years.currentSchoolYear,
    }),
    selectedStatus: function() {
      const tabEl = document.querySelector('.con-vs-tabs .activeChild')
      if (tabEl) {
        return tabEl.querySelector('button').value
      }
      return this.activeTab
    },
    studentsData: function() {
      const students = [...this.students]
      if (this.filters.student_class) {
        return students.sort((a, b) => {
          if (!a.testing_results?.length) return -1
          if (!b.testing_results?.length) return 1
          return a.testing_results[0].test_time - b.testing_results[0].test_time
        })
      }
      return students
    },
  },
  components: {
    StudentList,
    StudentForm,
    AddStudentToGroupForm,
    StudentListFilter,
  },
  created: async function() {
    this.filters = this.$route.query
    this.status = 1
    await this.getStudents()
    eventBus.$on('editStudent', this.editStudent)
    eventBus.$on('deleteStudent', this.deleteStudent)
    eventBus.$on('studentToQueue', this.studentToQueue)
    eventBus.$on('studentToTesting', this.studentToTesting)
    eventBus.$on('studentToActive', this.studentToActive)
    eventBus.$on('updatedTesting', this.getStudents)
    eventBus.$on('studentToTrial', this.studentToTrial)
    eventBus.$on('studentStayGroup', this.studentStayGroup)
  },
  methods: {
    getStudents: async function(status) {
      if (!this.currentSchoolyear.id) return
      // добавляем лоадер

      let filter = {
        schoolyear_id: this.currentSchoolyear.id,
        ...this.filters,
      }
      filter.status = status || this.status

      if (document.getElementById('student-list-container')) {
        this.$vs.loading({
          container: '#student-list-container',
          scale: 0.6,
        })
      }

      await this.$store
        .dispatch('students/getStudentsAction', filter)
        .then(() => {
          this.$vs.loading.close('#student-list-container > .con-vs-loading')
        })
    },
    editStudent: function(id) {
      if (!id) {
        this.$vs.notify({
          title: 'Error',
          text: `Error open student`,
          color: 'danger',
          position: 'top-right',
        })
        this.selectedStudent = {}
        return
      }
      //   this.selectedStudent = this.students.find(s => s.id == id);
      const schoolyear_id = this.currentSchoolyear.id
      this.$store
        .dispatch('students/getSingleStudentAction', { id, schoolyear_id })
        .then(() => {
          this.selectedStudent = this.singleStudent
          this.popupActive = true
        })
    },
    deleteStudent: function(id) {
      this.$store
        .dispatch('students/deleteStudentsAction', id)
        .then(() => {
          this.selectedStudent = {}
          this.getStudents()
          this.popupActive = false
          this.$vs.notify({
            title: 'Removed',
            text: `Student removed`,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(r => {
          this.selectedStudent = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    afterUpdateStudent: async function() {
      await this.getStudents()
    },
    studentToTesting: async function(id) {
      this.selectedStudent = this.students.find(s => s.id == id)
      if (!this.selectedStudent) return
      await this.saveStatusToStudent(id, StudentStatuses.testing)
    },
    studentToQueue: async function(id) {
      await this.saveStatusToStudent(id, StudentStatuses.queue)
    },
    saveStatusToStudent: async function(id, status) {
      const data = {
        id,
        status,
      }
      await this.$store
        .dispatch('students/saveStudentsAction', {
          student: data,
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error on save student',
            text: '',
            color: 'danger',
            position: 'top-right',
          })
        })
      await this.getStudents()
    },
    studentToActive: function(id) {
      this.selectedStudent = this.students.find(s => s.id == id)
      if (!this.selectedStudent) return

      this.popupAddToGroupActive = true
    },
    studentToTrial: function(id) {
      this.selectedStudent = this.students.find(s => s.id == id)
      if (!this.selectedStudent) return

      this.popupAddToGroupActive = true
      this.addAsTrial = true
    },
    studentStayGroup: function(student_id, group) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Stay student in group ${group.name}`,
        text: `Оставить ученика в группе ${group.name}? Из других групп с пробными занятиями он будет удален!`,
        accept: async () => {
          await this.$store.dispatch('students/stayStudentInGroupAction', {
            id: student_id,
            group_id: group.id,
          })
          window.location.reload()
        },
      })
    },
    closePopup: function() {
      this.popupActive = false
      this.popupAddToGroupActive = false
      this.selectedStudent = {}
      this.addAsTrial = false
    },
    filterEvent: async function(filters) {
      this.filters = filters
      // let { student_class } = filters
      // let query = {}
      // if (student_class) {
      //   query.student_class = student_class
      // }
      // так как параметры URL меняются, то компонент обновляется
      // и список учеников запрашивается в функции created()
      // this.$router.replace({
      //   ...this.$router.currentRoute,
      //   query,
      // })
      this.getStudents()
    },
    tabClickHandler: async function(status) {
      await this.getStudents(status)
      this.status = status
    },
  },
  watch: {
    currentSchoolyear: function() {
      this.getStudents()
    },
  },
  beforeDestroy() {
    eventBus.$off('editStudent')
    eventBus.$off('deleteStudent')
    eventBus.$off('studentToQueue')
    eventBus.$off('studentToTesting')
    eventBus.$off('studentToActive')
    eventBus.$off('studentToTrial')
    eventBus.$off('studentStayGroup')
  },
}
</script>

<style lang="scss">
.vs-con-table header {
  min-height: 50px;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #eee;
}
.vs-con-table {
  background-color: #fff;
  table {
    font-size: 1;
    td.is-has-relative {
      span.student-name {
        border: 2px solid #a903fc;
        border-radius: 3px;
        padding: 0 3px;
      }
    }
  }
}
.students__list {
  clear: both;
  table {
    thead {
      position: sticky;
      top: 45px;
      height: 2rem;
    }
  }
}
.students__add-button {
  button {
    margin-bottom: 10px;
  }
}
.students__popup .vs-popup {
  width: 75%;
}
</style>
