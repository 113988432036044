var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"testing-result-edit"},[_c('div',{staticClass:"testing-result-edit__close",on:{"click":function($event){return _vm.$emit('closeCommentForm')}}},[_c('vs-icon',{attrs:{"icon":"close"}})],1),_c('twitter-picker',{attrs:{"default-colors":[
      '#FF6900',
      '#FCB900',
      '#7BDCB5',
      '#00D084',
      '#8ED1FC',
      '#0693E3',
      '#ABB8C3',
      '#EB144C',
      '#F78DA7',
      '#9900EF',
      '#fff' ],"width":"320px"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('div',{staticClass:"comment__field"},[_c('quill-editor',{directives:[{name:"focus",rawName:"v-focus"}],ref:"myQuillEditor",attrs:{"options":_vm.editorOption},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.togglePicker),expression:"togglePicker"}],staticClass:"comment__emoji"},[_c('vs-icon',{attrs:{"icon":"tag_faces"},on:{"click":function($event){_vm.showPicker = !_vm.showPicker}}}),_c('VEmojiPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPicker),expression:"showPicker"}],on:{"select":_vm.selectEmoji}})],1)],1),_c('div',{staticClass:"save-tooltip__content"},[_c('vs-button',{attrs:{"data-type":"cell-action-button","type":"flat","icon":"check","title":"Save"},on:{"click":_vm.saveHandle}},[_vm._v("Save")]),_c('vs-button',{attrs:{"data-type":"cell-action-button","type":"flat","color":"dark","icon":"close","title":"Cancel"},on:{"click":_vm.cancelEditHandle}},[_vm._v("Cancel")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }