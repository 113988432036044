<template>
  <div class="student__filters">
    <div class="filter-item field">
      <vs-select
        placeholder="Grade"
        class="groups__select"
        label="Grade"
        v-model="filters.student_class"
        multiple
      >
        <vs-select-item
          :key="index"
          :value="grade"
          :text="grade.toString()"
          v-for="(grade, index) in grades"
        />
      </vs-select>
    </div>
    <div class="filter-item field">
      <vs-select
        placeholder="Shift"
        class="shift__select"
        label="Shift"
        v-model="filters.shift"
        multiple
      >
        <vs-select-item
          :key="index"
          :value="shift"
          :text="shift.toString()"
          v-for="(shift, index) in shifts"
        />
      </vs-select>
    </div>
    <div class="field">
      <vs-button @click="filterHandler">Filter</vs-button>
      <vs-button type="flat" color="dark" @click="clearFilterHandler"
        >Clear</vs-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['existFilters'],
  data() {
    return {
      grades: Array.from({ length: 12 }, (_, i) => i),
      shifts: Array.from({ length: 2 }, (_, i) => i + 1),
      filters: {
        student_class: [],
        shift: [],
      },
    }
  },
  methods: {
    filterHandler: function() {
      const filters = {
        student_class: this.filters.student_class.join(','),
        shift: this.filters.shift.join(','),
      }
      this.$emit('filterEvent', filters)
    },
    clearFilterHandler: function() {
      this.filters.student_class = []
      this.filters.shift = []
      const filters = { ...this.filters }
      this.$emit('filterEvent', filters)
    },
  },
  created() {
    // this.filters = { ...this.existFilters }
    const { student_class, shift } = this.existFilters
    if (student_class) {
      this.filters.student_class = student_class.split(',')
    }
    if (shift) {
      this.filters.shift = shift.split(',')
    }
  },
}
</script>

<style lang="scss" scoped>
.student__filters {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 1rem 0;
  padding: 0.2rem 1.2rem;
  align-items: flex-end;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0.2rem;
    background-color: #ddd;
  }
}
.field {
  margin: 0 1rem 0 0;
  .vs-button {
    margin: 0 0.2rem;
  }
  label {
    font-size: 0.8rem;
  }
}
</style>
