<template>
  <div class="testing-result-edit">
    <div class="testing-result-edit__close" @click="$emit('closeCommentForm')">
      <vs-icon icon="close"></vs-icon>
    </div>
    <twitter-picker
      v-model="color"
      :default-colors="[
        '#FF6900',
        '#FCB900',
        '#7BDCB5',
        '#00D084',
        '#8ED1FC',
        '#0693E3',
        '#ABB8C3',
        '#EB144C',
        '#F78DA7',
        '#9900EF',
        '#fff',
      ]"
      width="320px"
    />
    <div class="comment__field">
      <quill-editor
        ref="myQuillEditor"
        v-model="comment"
        :options="editorOption"
        v-focus
      />

      <div class="comment__emoji" v-click-outside="togglePicker">
        <vs-icon icon="tag_faces" @click="showPicker = !showPicker"></vs-icon>
        <VEmojiPicker v-show="showPicker" @select="selectEmoji" />
      </div>
    </div>
    <div class="save-tooltip__content">
      <vs-button
        data-type="cell-action-button"
        type="flat"
        icon="check"
        title="Save"
        @click="saveHandle"
        >Save</vs-button
      >
      <vs-button
        data-type="cell-action-button"
        type="flat"
        color="dark"
        icon="close"
        title="Cancel"
        @click="cancelEditHandle"
        >Cancel</vs-button
      >
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'
import { Twitter } from 'vue-color'
import { VEmojiPicker } from 'v-emoji-picker'
import vClickOutside from 'v-click-outside'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  props: {
    result: {
      type: Object,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
    focus: {
      // определение директивы
      inserted: function(el) {
        setTimeout(() => {
          el.querySelector('.ql-editor').focus()
        }, 50)
      },
    },
  },
  data() {
    var bindings = {
      custom: {
        key: 'Enter',
        ctrlKey: true,
        handler: function() {
          this.saveComment()
        }.bind(this),
      },
    }
    return {
      comment: this.result.comment,
      color: this.result.color || '#fff',
      showPicker: false,
      editorOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['clean'],
          ],
          keyboard: {
            bindings,
          },
        },
      },
    }
  },
  methods: {
    saveHandle: function() {
      const { id, lesson_id, student_id } = this.result
      const data = {
        id,
        color: this.color.hex,
        comment: this.comment,
        lesson_id,
        student_id,
      }
      this.$store
        .dispatch('results/setIncomeTestResultAction', { data: [data] })
        .then(() => {
          this.$vs.notify({
            title: 'Result saved',
            text: `Result saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$emit('closeCommentForm')
          eventBus.$emit('updatedTesting')
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e.message}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    cancelEditHandle: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are You sure to cancel changes?',

        accept: () => {
          this.fieldValue = this.value
          this.viewSaveButtons = false
          this.$refs.field.classList.remove('changed')
        },
      })
    },
    selectEmoji: function(emoji) {
      var range = this.editor.getSelection(true)
      this.editor.insertText(range ? range.index : 0, emoji.data)
    },
    togglePicker: function() {
      this.showPicker = false
    },
  },
  watch: {
    value: function(newV) {
      this.fieldValue = newV
    },
  },
  components: {
    'twitter-picker': Twitter,
    VEmojiPicker,
    quillEditor,
  },
  computed: {
    editor: function() {
      return this.$refs.myQuillEditor.quill
    },
  },
}
</script>

<style lang="scss">
.testing-result-edit {
  padding: 3rem 1rem 2rem 1rem;
  background-color: #fff;
  &__close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 10;
    i {
      border-radius: 50%;
      padding: 7px;
      transition: 0.3s all;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}
</style>
