<template>
  <div class="balance">
    <vs-dropdown vs-custom-content>
      <a class="balance-header" href.prevent>
        <span>
          Balance:
          <span
            class="credit"
            :class="{ negative: student.balance.balance_all < 0 }"
          >
            {{ student.balance.balance_all }} р.
          </span>
        </span>
        <span>
          Free:
          <span> {{ student.balance_unaccepted }} р. </span>
        </span>
      </a>
      <vs-dropdown-menu class="balancex">
        <div class="balance-items">
          <div
            class="balance-item"
            :class="{ marked: student.balance_unaccepted != 0 }"
          >
            <div class="balance-item__label">Free</div>
            <div class="balance-item__value">
              {{ student.balance_unaccepted }} р.
            </div>
            <div
              class="balance-item__manage"
              v-if="student.balance_unaccepted > 0"
            >
              <vs-button
                :disabled="student.balance_unaccepted <= 0"
                @click="
                  openTransferFormHandler('free', student.balance_unaccepted)
                "
                >Перевести</vs-button
              >
            </div>
          </div>
          <div class="balance-item" :class="{ marked: groupBalance != 0 }">
            <div class="balance-item__label">Groups</div>
            <div
              class="balance-item__value credit"
              :class="{ negative: groupBalance < 0 }"
            >
              {{ groupBalance }} р.
            </div>
            <div class="balance-item__manage" v-if="groupBalance > 0">
              <vs-button @click="openTransferFormHandler('group', groupBalance)"
                >Перевести</vs-button
              >
            </div>
          </div>
          <div class="balance-item" :class="{ marked: lessonBalance != 0 }">
            <div class="balance-item__label">Lessons</div>
            <div
              class="balance-item__value credit"
              :class="{ negative: lessonBalance < 0 }"
            >
              {{ lessonBalance }} р.
            </div>
            <div class="balance-item__manage" v-if="lessonBalance > 0">
              <vs-button
                @click="openTransferFormHandler('lesson', lessonBalance)"
                >Перевести</vs-button
              >
            </div>
          </div>
          <div class="balance-item" :class="{ marked: bookBalance != 0 }">
            <div class="balance-item__label">Books</div>
            <div
              class="balance-item__value credit"
              :class="{ negative: bookBalance < 0 }"
            >
              {{ bookBalance }} р.
            </div>
            <div class="balance-item__manage" v-if="bookBalance > 0">
              <vs-button @click="openTransferFormHandler('book', bookBalance)"
                >Перевести</vs-button
              >
            </div>
          </div>
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>
    <vs-popup
      class="form-popup students-transfer-popup"
      classContent="students-transfer__popup-content"
      title="Transfer"
      :active.sync="transferFromPopupActive"
    >
      <transfer-from
        v-if="transferFromPopupActive"
        :valueToTransfer="value"
        :typeFrom="typeFrom"
        @closePopup="closePopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import transferFrom from "./transfers/transferFrom.vue";
export default {
  components: { transferFrom },
  data() {
    return {
      transferFromPopupActive: false,
      value: 0,
      typeFrom: "free",
    };
  },
  computed: {
    ...mapState({
      student: (state) => state.students.singleStudent,
    }),
    groupBalance: function () {
      return this.student.balance.group || 0;
    },
    lessonBalance: function () {
      return this.student.balance.tutor || 0;
    },
    bookBalance: function () {
      return this.student.balance.book || 0;
    },
  },
  methods: {
    openTransferFormHandler: function (typeFrom, value) {
      this.typeFrom = typeFrom;
      this.transferFromPopupActive = true;
      this.value = value;
    },
    closePopup: function () {
      this.transferFromPopupActive = false;
    },
  },
};
</script>

<style lang="scss">
.balance-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px;
  &.marked {
    font-weight: bold;
  }
  .balance-item__label {
    width: 100px;
  }
  .balance-item__value {
    width: 100px;
    text-align: right;
  }
  .balance-item__manage {
    padding-left: 10px;
  }
}
.balance-items {
  padding: 10px;
}
a.balance-header {
  display: block;
  padding: 10px;
  transition: 0.2s all;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: auto;
  border: 1px solid transparent;
  &:hover {
    border-color: #ccc;
  }
}
</style>