<template>
  <div>
    <div class="collapsible">
      <div class="collapsible__orders" :class="{ opened: orderOpened }">
        <div class="collapsible__button" @click="orderOpened = !orderOpened">
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
          <vs-icon icon="insert_chart_outlined"></vs-icon>
          <span class="title">Orders</span>
          <span v-if="student.id">
            <totals-data :totals="totals" type="student-group" :group="group" />
          </span>
        </div>
        <div class="collapsible_content" v-if="orderOpened">
          <div v-if="orderType == 'group'">
            <OrdersTable :student="student" :group="group" />
          </div>
          <div v-if="orderType == 'lesson'">
            <table-lesson :student="student" />
          </div>
          <div v-if="orderType == 'groupTutor'">
            <table-lesson :student="student" :group="group" />
          </div>
          <div v-if="orderType == 'book'">
            <table-book :student="student" :group="group" />
          </div>
        </div>
      </div>
    </div>
    <div class="collapsible">
      <div class="collapsible__payments" :class="{ opened: paymentOpened }">
        <div
          class="collapsible__button"
          @click="paymentOpened = !paymentOpened"
        >
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
          <vs-icon icon="attach_money"></vs-icon>
          <span class="title">Payments</span>
        </div>
        <div class="collapsible_content" v-if="paymentOpened">
          <PaymentsTable
            :student="student"
            :group="group"
            :orderType="orderType"
            :schoolYearId="schoolYearId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersTable from '@/components/students/orders/table'
import PaymentsTable from '@/components/students/orders/paymentsStudentTable'
import TotalsData from '../../payments/statistic/totalsData.vue'
import getStatTotals from '@/api/payments/getStatTotals'
import TableLesson from './tableLesson.vue'
import TableBook from './tableBook.vue'

export default {
  props: {
    orderType: {
      type: String,
      default: 'group',
    },
    student: Object,
    group: Object,
    schoolYearId: Number,
  },
  data() {
    return {
      orderOpened: localStorage.getItem('orderOpened') === 'true' || false,
      paymentOpened: localStorage.getItem('paymentOpened') === 'true' || false,
      totals: {},
    }
  },
  components: {
    OrdersTable,
    PaymentsTable,
    TotalsData,
    TableLesson,
    TableBook,
  },
  watch: {
    orderOpened: function(newV) {
      localStorage.setItem('orderOpened', newV)
    },
    paymentOpened: function(newV) {
      localStorage.setItem('paymentOpened', newV)
    },
  },
  methods: {
    getTotalStatByStudent: function() {
      if (this.orderType != 'group') return
      // получаем стату только для группы
      const today = new Date()

      const studentGroup = this.student.groups.find(g => g.id == this.group.id)
      const dateFrom =
        today.getTime() <= studentGroup.start_from * 1000
          ? new Date(studentGroup.start_from * 1000)
          : today

      const dateTo = new Date(studentGroup.insert_date)

      const query = {
        'filter.groups': [this.group.id],
        'filter.students': [this.student.id],
        'filter.date_from': Math.floor(dateFrom.getTime() / 1000),
        'filter.date_to': Math.floor(dateTo.getTime() / 1000),
      }
      getStatTotals.call(this, query).then(response => {
        this.totals = response.data
      })
    },
  },
  created() {
    setTimeout(() => {
      this.getTotalStatByStudent()
    }, 100)
  },
}
</script>

<style lang="scss"></style>
