<template>
  <div class="students__list">
    <students-table-testing
      :data="data"
      :statusID="statusID"
      :itemsCount="totalItems"
      @searchStudent="searchHandler"
      v-if="statusID == 2"
    />
    <students-table-trial
      :data="data"
      :statusID="statusID"
      :itemsCount="totalItems"
      @searchStudent="searchHandler"
      v-else-if="statusID == 6"
    />
    <div v-else>
      <students-table
        :data="data"
        :statusID="statusID"
        :itemsCount="totalItems"
        @searchStudent="searchHandler"
      />
      <infinite-loading :distance="300" @infinite="infiniteHandler">
        <div slot="spinner">Loading...</div>
        <div slot="no-more">All done!</div>
        <div slot="no-results">All done!</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import studentsTable from './studentsTable.vue'
import StudentsTableTesting from './studentsTableTesting.vue'
import StudentsTableTrial from './studentsTableTrial.vue'

export default {
  components: { studentsTable, StudentsTableTesting, StudentsTableTrial },
  props: ['statusID', 'students'],
  data() {
    return {
      selected: [],
      selectedId: null,
      page: 0,
      perPage: 20,
      searchText: '',
    }
  },
  computed: {
    ...mapState({
      // students: state => state.students.students,
    }),
    data: function() {
      if (this.statusID == 2) {
        // если тестирование, то возвращаем весь список учеников
        return this.students
      }
      // return this.students
      const items = this.students.filter(i =>
        i.name.toLowerCase().includes(this.searchText.toLowerCase())
      )
      return items.slice(0, (this.page + 1) * this.perPage)
    },
    totalItems: function() {
      return this.students.length
    },
  },
  methods: {
    deleteStudent: function(id) {
      this.selectedId = id
      this.openAlert()
    },
    openAlert: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete student',
        text: 'Are you sure you want to delete the student?',
        accept: this.acceptAlert,
      })
    },
    acceptAlert: function() {
      this.$emit('deleteStudent', this.selectedId)
      this.selectedId = null
    },
    searchHandler: function(searchText) {
      this.searchText = searchText
    },
    infiniteHandler: function($state) {
      setTimeout(() => {
        if (this.students.length == 0) $state.loaded()
        this.page++
        $state.loaded()
        if (this.page * this.perPage > this.totalItems) {
          $state.complete()
        }
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.students__list {
  table {
    thead {
      position: sticky;
      top: 50px;
    }
  }
  td {
    &.manage-students {
      .manage-buttons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
      }
      .vs-button {
        float: none;
      }
    }
  }
  &__count {
    margin-left: 2rem;
    &:before {
      content: '';
      display: inline-block;
      width: 0.45rem;
      height: 0.45rem;
      background-color: rgba(var(--vs-primary));
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 0.2rem;
    }
  }
}
</style>
