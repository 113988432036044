<template>
  <div class="students-testing">
    <trial-table :items="data"></trial-table>
  </div>
</template>

<script>
import TrialTable from './trial/trialTable.vue'

export default {
  props: ['data', 'statusID'],
  data() {
    return {}
  },
  methods: {
    doubleClickHandler: function(tr) {
      this.$router.push({ name: 'Student', params: { student_id: tr.id } })
    },
    handleSearch(searching) {
      this.$emit('searchStudent', searching)
    },
  },
  components: { TrialTable },
}
</script>

<style lang="scss" scoped></style>
