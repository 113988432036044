<template>
  <div class="testing-grade">
    <div
      class="testing-grade__item"
      v-for="(item, key) in grouppedStudentList"
      :key="key"
    >
      <div class="testing-grade__name">
        <h3 v-if="!key">Empty Grade</h3>
        <h3 v-else>Grade {{ key }}</h3>
      </div>
      <TestingTable :items="Object.values(item)" :withShift="true" />
    </div>
  </div>
</template>

<script>
import TestingTable from './testingTable.vue'

export default {
  props: ['data'],
  components: { TestingTable },
  computed: {
    grouppedStudentList: function() {
      let output = []
      output = this.data.reduce((out, item) => {
        let itemData = [item]
        // берем класс
        let grade = out[item.student_class] || []

        // Обновляем/добавляем класс в итоговый объект
        out[item.student_class] = [...grade, ...itemData]
        return out
      }, {})
      output = Object.keys(output)
        .sort()
        .reduce((out, itemKey) => {
          out[itemKey] = output[itemKey]
          return output
        }, {})
      return output || this.data
    },
  },
}
</script>

<style lang="scss"></style>
