<template>
  <div>
    <div class="table__group-data">
      <h3>Study time</h3>

      <vs-row
        class="table__study-time-item"
        :class="{ active: !time.exit }"
        v-for="(time, index) in groupData.study_time"
        :key="index"
      >
        <vs-col vs-lg="1">{{ index + 1 }}</vs-col>
        <vs-col vs-lg="5">
          <div class="field">
            <label>Insert date</label>
            <v-date-picker v-model="time.insert" :masks="calendarMask">
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <vs-input
                    class="calendar-input"
                    :value="`${inputValue}`"
                    v-on="inputEvents"
                    size="large"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </vs-col>
        <vs-col vs-lg="1"><vs-icon icon="arrow_right_alt"></vs-icon></vs-col>
        <vs-col vs-lg="5">
          <div v-if="time.exit" class="field">
            <label>Exit date</label>
            <v-date-picker v-model="time.exit" :masks="calendarMask">
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <vs-input
                    class="calendar-input"
                    :value="`${inputValue}`"
                    v-on="inputEvents"
                    size="large"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
          <div v-else>
            <div class="field">
              <label for="">Exclude</label>
              <exclude-student :group_id="group.id" :student_id="student.id" />
            </div>
          </div>
        </vs-col>
      </vs-row>

      <h3>Discounts</h3>
      <div class="field">
        <div class="discount-list">
          <div
            v-for="(monthDiscount, index) in groupDisountsByMonths"
            :key="index"
            class="field__discount-params"
            :class="{
              'exist-discount': monthDiscount.discount,
              'discount-15': monthDiscount.discount == 15,
              'discount-25': monthDiscount.discount == 25,
              'discount-50': monthDiscount.discount == 50,
              'discount-100': monthDiscount.discount == 100,
            }"
          >
            <span class="month">{{ monthDiscount.name }}</span>
            <vs-select
              v-model="monthDiscount.discount"
              @change="changeDisount($event, groupData.id, monthDiscount)"
            >
              <vs-select-item :key="0" :value="null" text="No discount" />
              <vs-select-item :key="15" :value="15" text="15%" />
              <vs-select-item :key="25" :value="25" text="25%" />
              <vs-select-item :key="50" :value="50" text="50%" />
              <vs-select-item :key="100" :value="100" text="100%" />
            </vs-select>
            <!-- <vs-radio
              v-model="monthDiscount.discount"
              :vs-name="`discount_${monthDiscount.month}`"
              :vs-value="null"
              @change="changeDisount($event, groupData.id, monthDiscount)"
              >No discount</vs-radio
            >
            <vs-radio
              v-model="monthDiscount.discount"
              :vs-name="`discount_${monthDiscount.month}`"
              :vs-value="15"
              @change="changeDisount($event, groupData.id, monthDiscount)"
              >15%</vs-radio
            >
            <vs-radio
              v-model="monthDiscount.discount"
              :vs-name="`discount_${monthDiscount.month}`"
              :vs-value="25"
              @change="changeDisount($event, groupData.id, monthDiscount)"
              >25%</vs-radio
            >
            <vs-radio
              v-model="monthDiscount.discount"
              :vs-name="`discount_${monthDiscount.month}`"
              :vs-value="100"
              @change="changeDisount($event, groupData.id, monthDiscount)"
              >100%</vs-radio
            > -->
          </div>
        </div>
      </div>
    </div>
    <vs-button icon="save" @click="submitStudent" :disabled="saving"
      >Save</vs-button
    >
  </div>
</template>

<script>
import getSchoolYearMonths from '@/plugins/schoolYears'
import excludeStudent from './excludeStudent.vue'

export default {
  components: { excludeStudent },
  props: ['student', 'group', 'schoolyear'],
  data() {
    let schoolyearMonths = getSchoolYearMonths(this.group)

    let groupDisountsByMonths = [
      {
        month: 0,
        name: 'all',
        discount: null,
      },
      ...schoolyearMonths.map(syear => {
        return {
          month: syear.current,
          name: syear.name,
          discount: null,
        }
      }),
    ]
    if (this.group.discount && this.group.discount.length) {
      groupDisountsByMonths = groupDisountsByMonths.map(g => {
        let dataFromGroup = this.group.discount.filter(
          discount => discount.month == g.month
        )
        if (dataFromGroup.length) {
          return {
            ...g,
            discount: dataFromGroup[0].discount,
          }
        }
        return g
      })
    }
    let groupData = {
      ...this.group,
      study_time: this.group.study_time.map(s => {
        return {
          insert: new Date(s.insert * 1000),
          exit: s.exit ? new Date(s.exit * 1000) : null,
        }
      }),
      insert_date: new Date(this.group.insert_date),
      exit_date: this.group.exit_date ? new Date(this.group.exit_date) : null,
    }
    return {
      groupDisountsByMonths,
      groupData,
      saving: false,
      calendarMask: {
        input: 'DD/MM/YYYY',
      },
    }
  },
  methods: {
    changeDisount: function(e, groupId, discountData) {
      if (discountData.month == 0) {
        this.groupDisountsByMonths = this.groupDisountsByMonths.map(g => {
          return {
            ...g,
            discount: discountData.discount,
          }
        })
      }
    },
    submitStudent: function() {
      const { id, study_time } = this.groupData
      this.saving = true
      const data = {
        group_id: id,
        discount: this.groupDisountsByMonths.filter(
          g => g.month != 0 && g.discount !== null
        ),
        study_time: study_time.map(s => {
          return {
            insert: s.insert.getTime() / 1000,
            exit: s.exit ? s.exit.getTime() / 1000 : null,
          }
        }),
      }
      if (this.$store) {
        this.$store
          .dispatch('students/saveStudentGroupAction', {
            student: this.student,
            group: this.groupData.id,
            groupData: [data],
          })
          .then(() => {
            this.$emit('closePopup', this.closePopup)
            this.saving = false
          })
          .catch(() => {
            this.saving = false
          })
      } else {
        this.$emit('saveStudent', [data])
        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss">
.table__group-data {
  h3 {
    margin-top: 0.5rem;
  }
  .vs-row {
    align-items: center;
  }
  .field {
    margin: 5px 15px 10px 0;
    & > label {
      font-weight: bold;
      padding: 7px 0;
    }
    .field__discount-params {
      padding: 2px 5px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border-radius: 5px;
      &::first-child() {
        font-weight: bold;
      }
      &:hover {
        background-color: #eee;
      }
      &.exist-discount {
        &.discount-15 {
          color: var(--font-discount-15);
        }
        &.discount-25 {
          color: var(--font-discount-25);
        }
        &.discount-50 {
          color: var(--font-discount-50);
        }
        &.discount-100 {
          color: var(--font-discount-100);
        }
      }
      .con-vs-checkbox,
      .month {
        justify-content: flex-start;
        min-width: 150px;
      }
      .con-vs-radio {
        margin: 5px 10px;
        .vs-radio {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .con-select {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
