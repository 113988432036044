<template>
  <div
    class="testing-grade-grid"
    :class="{ 'with-grade': withGrade, 'with-shift': withShift }"
  >
    <div class="head">
      <div class="name">Student</div>

      <div class="grade" v-if="withGrade">Grade</div>
      <div class="shift" v-if="withShift">Shift</div>

      <div class="results">
        <div class="result">
          <div class="color"></div>
          <div class="time">Date</div>
          <div class="test-name">Test name</div>
          <div class="oral-value">Oral part</div>
          <div class="written-value">Written part</div>
          <div class="lesson-comment">Lesson comment</div>
          <div class="comment">Comment</div>
        </div>
      </div>
      <div class="options"></div>
    </div>
    <div class="row" v-for="(item, index) in items" :key="index">
      <div class="name">
        <vs-icon icon="person" size="small"></vs-icon>
        <router-link
          :to="{ name: 'Student', params: { student_id: item.id } }"
          class="student-link"
        >
          {{ item.name }}
        </router-link>
      </div>
      <div class="student_class" v-if="withGrade">
        {{ item.student_class || '-' }}
      </div>
      <div class="shift" v-if="withShift">{{ item.shift || '-' }}</div>

      <div class="results">
        <div
          class="result"
          v-for="(result, index) in item.testing_results"
          :key="index"
          @click="editResultHandler($event, result)"
        >
          <div
            class="color"
            :style="{ backgroundColor: result.color || '#fff' }"
          ></div>
          <div class="result__time">
            {{ $moment(result.test_time * 1000).format('DD-MM-YYYY') }}
            <div class="result__exist" v-if="result.lesson_exist == 'н'">
              Не пришел
            </div>
          </div>
          <div class="result__name">
            <router-link
              :to="{
                name: 'IncomeResult',
                params: { result_id: result.id },
              }"
              target="_blank"
            >
              {{ result.test.name || '-' }}
            </router-link>
          </div>
          <div class="result__oral-value">{{ result.oral_value }}</div>
          <div class="result__written-value">{{ result.written_value }}</div>
          <div class="result__lesson-comment">
            <vs-tooltip :text="result.lesson_comment" position="top">
              <div
                v-if="result.lesson_comment"
                class="students-test-result__comment__teacher"
              >
                <vs-icon
                  icon="comment"
                  size="small"
                  v-if="result.lesson_comment"
                  color="dark"
                ></vs-icon>
                <vs-avatar
                  size="16px"
                  :src="
                    result.teachers_options.comment.teacher.options.avatar
                      ? `${proxy}/static/files/${result.teachers_options.comment.teacher.options.avatar}`
                      : undefined
                  "
                ></vs-avatar>
              </div>
              {{
                result.lesson_comment
                  ? result.lesson_comment.slice(0, 25)
                  : '-'
              }}...
            </vs-tooltip>
          </div>
          <div
            class="testing-result__comment"
            v-html="result.comment || '-'"
          ></div>
        </div>
      </div>
      <div class="options">
        <vs-dropdown>
          <vs-button
            class="btn-drop"
            type="flat"
            color="dark"
            icon="more_horiz"
          ></vs-button>
          <!-- <a href="#">Hola mundo</a> -->

          <vs-dropdown-menu class="result-options-menu">
            <vs-dropdown-item>
              <vs-button
                type="flat"
                icon="edit"
                size="small"
                v-if="$can('manage', 'student')"
                @click="eventBus.$emit('editStudent', item.id)"
                >Edit student</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                type="flat"
                color="dark"
                icon="visibility"
                size="small"
                @click="
                  $router.push({
                    name: 'Student',
                    params: { student_id: item.id },
                  })
                "
                >View student</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="dark"
                icon="west"
                type="flat"
                size="small"
                @click="eventBus.$emit('studentToQueue', item.id)"
                >To Queue</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="#5b3cc4"
                icon="east"
                type="flat"
                size="small"
                @click="eventBus.$emit('studentToTrial', item.id)"
                >To Trial</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="primary"
                icon="east"
                type="flat"
                size="small"
                @click="eventBus.$emit('studentToActive', item.id)"
                >To Active</vs-button
              >
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>
    <div v-if="editPopupActive">
      <draggable-panel :X="dragPositionX" :Y="dragPositionY">
        <template v-slot:header> Edit </template>
        <TestingEditForm
          :result="selectedResult"
          @closeCommentForm="closeForm"
        />
      </draggable-panel>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'
import DraggablePanel from '@/components/common/elements/draggablePanel.vue'
import TestingEditForm from './testingEditForm.vue'

export default {
  props: ['items', 'withGrade', 'withShift'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      defaultColor: '#fff',
      eventBus: eventBus,
      editPopupActive: false,
      dragPositionX: 0,
      dragPositionY: 0,
      selectedResult: null,
    }
  },
  components: {
    DraggablePanel,
    TestingEditForm,
  },
  methods: {
    editResultHandler: function(event, item) {
      this.selectedResult = item
      this.editPopupActive = true
      this.dragPositionX = event.x
      this.dragPositionY = event.y
    },
    closeForm: function() {
      this.selectedResult = false
      this.editPopupActive = false
    },
  },
}
</script>

<style lang="scss">
.testing-grade-grid {
  font-size: 0.8rem;
  // костыли, но так проще
  &.with-grade {
    .row,
    .head {
      grid-template-columns: 0.5fr 2.5rem 3.5fr 3rem;
    }
  }
  &.with-shift {
    .row,
    .head {
      grid-template-columns: 0.5fr 2.5rem 3.5fr 3rem;
    }
  }
  &.with-shift.with-grade {
    .row,
    .head {
      grid-template-columns: 0.5fr 2.5rem 2.5rem 3.3fr 3rem;
    }
  }
  .row,
  .head {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 4fr 3rem;
    & > div {
      padding: 8px;
    }
  }
  .results {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr;
  }
  .result {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1.5rem repeat(4, 1fr) 2fr 3fr;
  }
  .student-position {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .head {
    background-color: #eee;
    margin-top: 0.8rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .row {
    &:hover {
      //   background-color: #f9f9f9;
    }
    .color {
      border: 1px solid #eee;
      border-radius: 5px;
      width: 1.1rem;
      height: 1.1rem;
    }
    .result {
      margin: 3px 0;
      padding: 8px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: #f9f9f9;
      }

      .students-test-result__comment__teacher i {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        opacity: 0.5;
      }
      &__exist {
        color: var(--font-negative-color);
        font-weight: bold;
      }
    }
    .options {
    }
  }
}
.result-options-menu {
  width: 150px;
  .vs-button {
    float: none !important;
    width: 100%;
    justify-content: flex-start;
  }
}
</style>
