<template>
  <div class="add-student-to-group-form">
    <form action="" @submit="submitForm($event)">
      <div class="add-group" v-for="(item, index) in items" :key="index">
        <div class="field">
          <vs-select
            autocomplete
            class="selectExample"
            label="Group"
            v-model="item.groupId"
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in groupsList"
            />
          </vs-select>
        </div>

        <div class="insert-date-calendar field">
          <label>Enter date</label>
          <v-date-picker v-model="item.insert_date" mode="date" ref="calendar">
            <template v-slot="{ inputValue, inputEvents }">
              <div>
                <vs-input
                  class="calendar-input"
                  :value="`${inputValue}`"
                  v-on="inputEvents"
                  size="large"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
    </form>
    <div class="add-group-button">
      <vs-button
        v-if="asTrial"
        type="flat"
        size="small"
        @click="addGroupHandler"
      >
        + Add group
      </vs-button>
    </div>
    <div class="buttons">
      <vs-button icon="save" @click="submitForm($event)">Save</vs-button>
      <vs-button icon="close" type="flat" @click="$emit('closeForm')"
        >Cancel</vs-button
      >
    </div>
  </div>
</template>

<script>
//   :text="`${item.name} (${item.schoolyear.name})`"
//
import { mapState } from 'vuex'
export default {
  props: ['student', 'isPopup', 'asTrial'],
  data() {
    return {
      items: [
        {
          groupId: null,
          insert_date: new Date(),
        },
      ],
      template: {
        groupId: null,
        insert_date: new Date(),
      },
      groupsList: [],
    }
  },
  computed: {
    ...mapState({
      groups: state => state.groups.groups,
      currentSchoolYear: state => state.years.currentSchoolYear,
    }),
  },
  methods: {
    submitForm: function(e) {
      e.preventDefault()
      this.items.forEach(async item => {
        await this.$store.dispatch('students/addStudentToGroupAction', {
          studentId: this.student.id,
          groupId: item.groupId,
          insert: item.insert_date.getTime() / 1000,
          is_trial: this.asTrial,
        })
      })
      if (this.isPopup) {
        this.$emit('updateStudents')
        this.$emit('closeForm')
      } else {
        window.location.reload()
      }
    },
    addGroupHandler: function() {
      this.items.push(Object.assign({}, this.template))
    },
  },
  created: async function() {
    if (!this.groups.length) {
      const data = {
        schoolyear_id: this.currentSchoolYear.id,
      }
      await this.$store.dispatch('groups/getGroupsAction', { data })
    }
    this.groupsList = this.groups
  },
}
</script>

<style lang="scss">
.add-student-to-group-form {
  .add-group {
    padding: 25px;
    margin: 20px 0;
    border-radius: 5px;
    background-color: #eee;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    .con-select,
    .insert-date-calendar {
      margin: 0 20px 0 0;
    }
    .insert-date-calendar {
      z-index: 400;
    }
    .buttons {
      & > * {
        margin-right: 10px;
      }
    }
  }
  .add-group-button .vs-button {
    display: block;
    float: none;
    margin-bottom: 1rem;
  }
}
</style>
