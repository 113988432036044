<template>
  <div class="trial-grade-grid">
    <div class="head">
      <div class="name">Student</div>

      <div class="grade">Grade</div>
      <div class="shift">Shift</div>

      <div class="results">
        <div class="result">
          <div class="group">Group</div>
          <div class="lesson-time">Lesson Date</div>
          <div class="comment">Comment</div>
        </div>
      </div>
      <div class="options"></div>
    </div>
    <div class="row" v-for="(item, index) in items" :key="index">
      <div class="name">
        <vs-icon icon="person" size="small"></vs-icon>
        <router-link
          :to="{ name: 'Student', params: { student_id: item.id } }"
          class="student-link"
        >
          {{ item.name }}
        </router-link>
      </div>
      <div class="student_class">
        {{ item.student_class || '-' }}
      </div>
      <div class="shift">{{ item.shift || '-' }}</div>

      <div class="results">
        <div
          class="result"
          v-for="(result, index) in item.trial_results"
          :key="index"
        >
          <div class="group">
            <group :id="result.id" :color="result.color">{{
              result.name
            }}</group>
          </div>
          <div class="result__lessons">
            <div class="result__lesson" v-if="!result.lessons"></div>

            <div
              class="result__lesson"
              v-for="(lesson, index) in result.lessons"
              :key="index"
            >
              <div class="lesson__time">
                <div>
                  {{ $moment(lesson.lesson_time * 1000).format('DD-MM-YYYY') }}
                </div>

                <router-link
                  :to="{
                    name: 'Lessons',
                    params: { lesson_id: lesson.id },
                  }"
                  target="_blank"
                >
                  {{ lesson.name }}
                </router-link>
              </div>
              <div class="lesson-comment">
                <div v-if="lesson.comment">
                  <div class="comment-text">{{ lesson.comment }}</div>
                  <vs-avatar
                    size="16px"
                    v-if="lesson.comment"
                    :src="
                      lesson.teachers_options.comment.teacher.options.avatar
                        ? `${proxy}/static/files/${lesson.teachers_options.comment.teacher.options.avatar}`
                        : undefined
                    "
                  ></vs-avatar>
                  {{ lesson.teachers_options.comment.teacher.nickname }}
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
          <div class="stay-here-button">
            <vs-button
              v-if="$can('manage', 'student')"
              color="#5b3cc4"
              icon="subdirectory_arrow_left"
              type="border"
              size="small"
              @click="eventBus.$emit('studentStayGroup', item.id, result)"
              >Stay here</vs-button
            >
          </div>
        </div>
      </div>

      <div class="options">
        <vs-dropdown>
          <vs-button
            class="btn-drop"
            type="flat"
            color="dark"
            icon="more_horiz"
          ></vs-button>
          <!-- <a href="#">Hola mundo</a> -->

          <vs-dropdown-menu class="result-options-menu">
            <vs-dropdown-item>
              <vs-button
                type="flat"
                icon="edit"
                size="small"
                v-if="$can('manage', 'student')"
                @click="eventBus.$emit('editStudent', item.id)"
                >Edit student</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                type="flat"
                color="dark"
                icon="visibility"
                size="small"
                @click="
                  $router.push({
                    name: 'Student',
                    params: { student_id: item.id },
                  })
                "
                >View student</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="dark"
                icon="west"
                type="flat"
                size="small"
                @click="studentToNewStatusHandler(item, 'studentToQueue')"
                >To Queue</vs-button
              >
              <vs-dropdown-item> </vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="dark"
                icon="west"
                type="flat"
                @click="studentToNewStatusHandler(item, 'studentToTesting')"
                size="small"
                >To Testing</vs-button
              >
            </vs-dropdown-item>
            <vs-dropdown-item>
              <vs-button
                v-if="$can('manage', 'student')"
                color="#5b3cc4"
                icon="east"
                type="flat"
                size="small"
                @click="eventBus.$emit('studentToTrial', item.id)"
                >To Trial</vs-button
              >
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>
    <vs-prompt
      @cancel="exit = new Date()"
      @accept="exludeStudentHandle"
      :active.sync="activePrompt"
      title="Exclude student from all trial groups?"
      color="danger"
    >
      <vs-alert color="danger">
        <strong>Важно!</strong>
        <p>
          Ученик будет исключен из всех групп с пробными знаятиями с указанной
          даты.
        </p>
        <p>
          Если посещаемость и комментарий будет
          <strong>позже этой даты</strong>, то эти данные
          <strong>удалятся</strong>!
        </p>
      </vs-alert>
      <div class="con-exemple-prompt field">
        <label for="">Enter exclude date</label>
        <v-date-picker v-model="exit"> </v-date-picker>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'
import Group from '@/components/common/elements/group.vue'
export default {
  props: ['items'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      defaultColor: '#fff',
      eventBus: eventBus,
      activePrompt: false,
      exit: new Date(),
      studentToExclude: null,
      event: null,
    }
  },
  components: { Group },
  methods: {
    studentToNewStatusHandler: function(item, event) {
      // показываем окно для выбора даты исключения из групп
      this.activePrompt = true
      this.studentToExclude = item
      this.event = event
    },
    exludeStudentHandle: function() {
      this.studentToExclude.trial_results.forEach(async group => {
        const data = {
          studentId: this.studentToExclude.id,
          groupId: group.id,
          exit: this.exit.getTime() / 1000,
          comment: `<b>Exclude student from Group ${group.name}.</b><p>Reason: was on trial</p>`,
          is_trial: true,
        }
        await this.$store.dispatch(
          'students/excludeStudentFromGroupAction',
          data
        )
      })
      eventBus.$emit(this.event, this.studentToExclude.id)
    },
  },
}
</script>

<style lang="scss">
.trial-grade-grid {
  font-size: 0.8rem;

  .row,
  .head {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2fr 1fr 1fr 12fr 3rem;
    & > div {
      padding: 8px;
    }
  }
  .head .result {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 5fr 1fr;
  }
  .results {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 2fr;
  }
  .result {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    .stay-here-button button {
      float: right;
    }
  }
  .result__lesson {
    display: grid;
    grid-template-columns: 1fr 5fr;
    .comment-text {
      margin-left: 5px;
    }
  }

  .head {
    background-color: #eee;
    margin-top: 0.8rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .row {
    &:hover {
      //   background-color: #f9f9f9;
    }
    .color {
      border: 1px solid #eee;
      border-radius: 5px;
      width: 1.1rem;
      height: 1.1rem;
    }
    .result {
      margin: 3px 0;
      padding: 8px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: #f9f9f9;
      }

      .students-test-result__comment__teacher i {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        opacity: 0.5;
      }
      &__exist {
        color: var(--font-negative-color);
        font-weight: bold;
      }
    }
    .options {
    }
  }
}
.result-options-menu {
  width: 150px;
  .vs-button {
    float: none !important;
    width: 100%;
    justify-content: flex-start;
  }
}
</style>
