<template>
  <div>
    <div class="orders__header"></div>
    <div class="order__table-wrapper">
      <table class="order__table" id="order-table">
        <thead>
          <th>Books</th>
          <th sort-key="value">To Pay</th>
          <th>Date</th>
          <th>Paid</th>
          <th>End Balance</th>
          <th>Closed</th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr
            v-for="(tr, indextr) in orders"
            :key="indextr"
            @click="openPayments($event, tr)"
          >
            <td class="order-table__books-list">
              <book-item
                :book="book"
                :group_id="tr.group_id"
                v-for="book in tr.books"
                :key="book.id"
              />
            </td>
            <td>{{ tr.value }}</td>
            <td>
              <span v-if="tr.last_payment_date">
                {{
                  $moment(tr.last_payment_date * 1000).format("DD.MM.YY HH:mm")
                }}
              </span>
            </td>
            <td class="highlight">{{ tr.payments || 0 }} р.</td>
            <td class="credit highlight" :class="{ negative: tr.balance < 0 }">
              {{ tr.balance || 0 }} р.
            </td>
            <td>
              <vs-icon icon="done" color="success" v-if="tr.closed"></vs-icon>
            </td>
            <td>
              <order-send-button
                :order="tr"
                @setOrderAsSended="setOrderAsSended"
                orderType="book"
              />
            </td>
            <td>
              <vs-tooltip
                title="order"
                :text="`id: ${tr.id};
                owner: ${tr.owner ? tr.owner.nickname : ' - '};
                editor: ${tr.user_id ? tr.user_id.nickname : ' - '};
                created: ${$moment(tr.created * 1000).format('DD.MM.YY HH:mm')};
                updated: ${$moment(tr.updated * 1000).format(
                  'DD.MM.YY HH:mm',
                )} `"
              >
                <vs-icon icon="error_outline" size="16px"></vs-icon>
              </vs-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <vs-popup
      class="form-popup payment__popup"
      classContent="payment__popup-content"
      :title="popuptTitle"
      :active.sync="popupActive"
      v-if="popupActive"
    >
      <PaymentWrapper
        v-if="popupActive"
        :student="student"
        :order="selectedOrder"
        @changedPayments="changedPayments"
      >
        <template v-slot:order>
          <div class="order-info">
            <span>Order #{{ selectedOrder.id }}</span>

            <vs-avatar icon="people" :color="group.color"></vs-avatar>
            <span>Books in {{ group.name }}</span>

            <span
              ><vs-chip>
                <vs-avatar icon="person" />
                {{ student.name }}
              </vs-chip></span
            >
            <span class="highlight">{{ selectedOrder.value }}р.</span>
            <span
              class="credit highlight"
              :class="{ negative: selectedOrder.balance < 0 }"
              >{{ selectedOrder.balance }}р.</span
            >
          </div>
        </template>
      </PaymentWrapper>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentWrapper from "@/components/payments/singleForm/wrapper";
import OrderSendButton from "../../orders/orderSendButton.vue";
import BookItem from "../../books/students/bookItem.vue";

export default {
  props: ["student", "group"],
  data() {
    return {
      popupActive: false,
      popuptTitle: "Payments",
      selectedOrder: {},
      changed: false,
    };
  },
  components: {
    PaymentWrapper,
    OrderSendButton,
    BookItem,
  },
  computed: {
    ...mapState({
      currentYear: (state) => state.years.currentSchoolYear,
    }),
    orders: function () {
      return this.group.orders;
    },
  },
  methods: {
    openPayments: function (e, tr) {
      e.preventDefault();
      this.popupActive = true;
      this.popuptTitle = `Payments to order #${tr.id}`;
      this.selectedOrder = tr;
    },
    reloadHandle: function () {
      const student = this.student.id;
      this.$store.dispatch("orders/getOrdersTutorByStudentAction", {
        student,
        schoolyear_id: this.currentYear ? this.currentYear.id : null,
      });
    },
    changedPayments: function () {
      this.updateLessons();
    },
    updateLessons: function () {
      const student = this.student.id;
      this.$store.dispatch("books/getStudentBooksAction", {
        student_id: student,
        schoolyear_id: this.currentYear ? this.currentYear.id : null,
      });
    },
    setOrderAsSended: function () {
      this.updateLessons().then(() => {
        this.$vs.notify({
          title: "Order marked as sended",
          color: "success",
          position: "top-right",
        });
      });
    },
  },
};
</script>

<style lang="scss">
.order__table {
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  position: relative;
  width: auto;
  .order-table__books-list {
    max-width: none !important;
  }
  th {
    vertical-align: top;
  }
  td,
  th {
    padding: 7px;
    text-align: left;
    font-size: 0.8em;
    max-width: 100px;
    vertical-align: top;
  }
  th {
    word-wrap: break-word;
    border-bottom: 1px solid #ccc;
  }
  tr {
    cursor: pointer;
    transition: 0.2s all;
    &:nth-child(2n),
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
.orders__header {
  margin-bottom: 7px;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.order-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > * {
    margin-right: 15px;
  }
  .con-vs-chip {
    float: none !important;
  }
}
.recalculate {
  border-radius: 5px;
  .recalculate-title {
    padding: 5px;
    font-size: 0.9em;
    display: block;
  }
  &:hover {
    background-color: #eee;
  }
}
</style>