var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trial-grade-grid"},[_vm._m(0),_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"name"},[_c('vs-icon',{attrs:{"icon":"person","size":"small"}}),_c('router-link',{staticClass:"student-link",attrs:{"to":{ name: 'Student', params: { student_id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"student_class"},[_vm._v(" "+_vm._s(item.student_class || '-')+" ")]),_c('div',{staticClass:"shift"},[_vm._v(_vm._s(item.shift || '-'))]),_c('div',{staticClass:"results"},_vm._l((item.trial_results),function(result,index){return _c('div',{key:index,staticClass:"result"},[_c('div',{staticClass:"group"},[_c('group',{attrs:{"id":result.id,"color":result.color}},[_vm._v(_vm._s(result.name))])],1),_c('div',{staticClass:"result__lessons"},[(!result.lessons)?_c('div',{staticClass:"result__lesson"}):_vm._e(),_vm._l((result.lessons),function(lesson,index){return _c('div',{key:index,staticClass:"result__lesson"},[_c('div',{staticClass:"lesson__time"},[_c('div',[_vm._v(" "+_vm._s(_vm.$moment(lesson.lesson_time * 1000).format('DD-MM-YYYY'))+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'Lessons',
                  params: { lesson_id: lesson.id },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(lesson.name)+" ")])],1),_c('div',{staticClass:"lesson-comment"},[(lesson.comment)?_c('div',[_c('div',{staticClass:"comment-text"},[_vm._v(_vm._s(lesson.comment))]),(lesson.comment)?_c('vs-avatar',{attrs:{"size":"16px","src":lesson.teachers_options.comment.teacher.options.avatar
                      ? (_vm.proxy + "/static/files/" + (lesson.teachers_options.comment.teacher.options.avatar))
                      : undefined}}):_vm._e(),_vm._v(" "+_vm._s(lesson.teachers_options.comment.teacher.nickname)+" ")],1):_c('div',[_vm._v("-")])])])})],2),_c('div',{staticClass:"stay-here-button"},[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"#5b3cc4","icon":"subdirectory_arrow_left","type":"border","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('studentStayGroup', item.id, result)}}},[_vm._v("Stay here")]):_vm._e()],1)])}),0),_c('div',{staticClass:"options"},[_c('vs-dropdown',[_c('vs-button',{staticClass:"btn-drop",attrs:{"type":"flat","color":"dark","icon":"more_horiz"}}),_c('vs-dropdown-menu',{staticClass:"result-options-menu"},[_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"type":"flat","icon":"edit","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('editStudent', item.id)}}},[_vm._v("Edit student")]):_vm._e()],1),_c('vs-dropdown-item',[_c('vs-button',{attrs:{"type":"flat","color":"dark","icon":"visibility","size":"small"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Student',
                  params: { student_id: item.id },
                })}}},[_vm._v("View student")])],1),_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"dark","icon":"west","type":"flat","size":"small"},on:{"click":function($event){return _vm.studentToNewStatusHandler(item, 'studentToQueue')}}},[_vm._v("To Queue")]):_vm._e(),_c('vs-dropdown-item'),(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"dark","icon":"west","type":"flat","size":"small"},on:{"click":function($event){return _vm.studentToNewStatusHandler(item, 'studentToTesting')}}},[_vm._v("To Testing")]):_vm._e()],1),_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"#5b3cc4","icon":"east","type":"flat","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('studentToTrial', item.id)}}},[_vm._v("To Trial")]):_vm._e()],1)],1)],1)],1)])}),_c('vs-prompt',{attrs:{"active":_vm.activePrompt,"title":"Exclude student from all trial groups?","color":"danger"},on:{"cancel":function($event){_vm.exit = new Date()},"accept":_vm.exludeStudentHandle,"update:active":function($event){_vm.activePrompt=$event}}},[_c('vs-alert',{attrs:{"color":"danger"}},[_c('strong',[_vm._v("Важно!")]),_c('p',[_vm._v(" Ученик будет исключен из всех групп с пробными знаятиями с указанной даты. ")]),_c('p',[_vm._v(" Если посещаемость и комментарий будет "),_c('strong',[_vm._v("позже этой даты")]),_vm._v(", то эти данные "),_c('strong',[_vm._v("удалятся")]),_vm._v("! ")])]),_c('div',{staticClass:"con-exemple-prompt field"},[_c('label',{attrs:{"for":""}},[_vm._v("Enter exclude date")]),_c('v-date-picker',{model:{value:(_vm.exit),callback:function ($$v) {_vm.exit=$$v},expression:"exit"}})],1)],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"name"},[_vm._v("Student")]),_c('div',{staticClass:"grade"},[_vm._v("Grade")]),_c('div',{staticClass:"shift"},[_vm._v("Shift")]),_c('div',{staticClass:"results"},[_c('div',{staticClass:"result"},[_c('div',{staticClass:"group"},[_vm._v("Group")]),_c('div',{staticClass:"lesson-time"},[_vm._v("Lesson Date")]),_c('div',{staticClass:"comment"},[_vm._v("Comment")])])]),_c('div',{staticClass:"options"})])}]

export { render, staticRenderFns }