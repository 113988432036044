<template>
  <div class="edit-button wrapper" title="Edit test result">
    <vs-button
      v-if="size == 'small'"
      type="flat"
      icon="edit"
      @click="editHandler"
      color="dark"
      size="small"
    >
    </vs-button>
    <vs-button v-else type="flat" icon="edit" @click="editHandler" color="dark"
      >Edit
    </vs-button>
    <vs-popup
      class="form-popup result__popup"
      classContent="result__popup-content"
      :title="''"
      :active.sync="popupActive"
      v-if="result.id && popupActive"
    >
      <ResultForm :result="result" @closePopup="closePopup" />
    </vs-popup>
  </div>
</template>

<script>
import ResultForm from "@/components/results/form.vue";

export default {
  name: "editResultButton",
  props: ["result", "size"],
  data() {
    return {
      popupActive: false,
    };
  },
  components: { ResultForm },
  methods: {
    closePopup: function () {
      this.popupActive = false;
    },
    editHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.popupActive = true;
    },
  },
};
</script>

<style>
</style>