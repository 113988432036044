<template>
  <div>
    <div class="payments__header">
      <vs-button
        type="flat"
        icon="refresh"
        color="dark"
        size="small"
        title="Refresh"
        @click="reloadHandle"
        >Refresh</vs-button
      >
      <vs-button type="flat" size="small">Export *.xls</vs-button>
    </div>
    <div class="payments__list">
      <PaymentList :payments="payments" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import getPayments from '@/api/payments/getPayments.js'
import PaymentList from '@/components/payments/singleForm/list'

export default {
  props: {
    orderType: {
      type: String,
      default: 'group',
    },
    student: Object,
    group: Object,
    schoolYearId: Number,
  },
  computed: {
    ...mapState({
      currentYear: state => state.years.currentSchoolYear,
    }),
  },
  data() {
    return {
      payments: [],
    }
  },
  components: {
    PaymentList,
  },
  created: function() {
    this.getPayments()
  },
  methods: {
    getPayments: function() {
      let query = {
        student_id: this.student.id,
      }
      if (this.orderType == 'group') {
        query.group_id = this.group.id
      } else if (this.orderType == 'groupTutor') {
        query.group_id = this.group.id
        query.schoolyear_id = this.schoolYearId || this.currentYear.id
        query.order_type = this.orderType
      } else if (this.orderType == 'lesson') {
        query.schoolyear_id = this.schoolYearId || this.currentYear.id
        query.order_type = this.orderType
      } else if (this.orderType == 'book') {
        query.schoolyear_id = this.schoolYearId || this.currentYear.id
        query.order_type = this.orderType
        query.group_id = this.group.id
        query.student_id = this.student.id
      }
      getPayments.call(this, query).then(data => {
        this.payments = data.data
      })
    },
    reloadHandle: function() {
      this.getPayments()
    },
  },
  watch: {
    schoolYearId: function() {
      this.getPayments()
    },
  },
}
</script>

<style lang="scss">
.payments__header {
  margin-bottom: 7px;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
.payments__list {
  width: 95%;
  max-width: 1010px;
}
</style>
