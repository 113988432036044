<template>
  <div>
    <div class="books__add-button add-button" v-if="$can('manage', 'books')">
      <vs-button icon="add" type="flat" @click="popupActive = true"
        >Add Book</vs-button
      >
    </div>
    <div class="group-books__list">
      <div class="group__item" v-for="group in books" :key="group.id">
        <div class="group__item__header">
          <vs-avatar icon="people" :color="group.color"></vs-avatar>
          <router-link
            :to="{ name: 'Group', params: { group_id: group.id } }"
            class="group-link"
          >
            Group: {{ group.name }}
          </router-link>
          <div
            class="group__balance"
            v-if="$can('manage', 'payments')"
            :set="(balance = group.orders.reduce((a, b) => a + b.balance, 0))"
          >
            Balance:
            <span class="credit highlight" :class="{ negative: balance < 0 }">
              {{ balance || 0 }} р.
            </span>
          </div>
        </div>
        <div class="group__free-books" v-if="group.books.length">
          <h3>Free books</h3>
          <div
            class="group-books__items"
            v-for="book in group.books"
            :key="book.id"
          >
            <book-item :book="book" :group_id="group.id" :free="true" />
          </div>
        </div>
        <div class="book-lesson-orders" v-if="$can('manage', 'payments')">
          <h3>Books with orders</h3>
          <OrdersWrapper :student="student" :group="group" orderType="book" />
        </div>
      </div>
    </div>
    <vs-popup
      class="books-group__popup"
      classContent="books__popup-content"
      title="Add book to student"
      :active.sync="popupActive"
    >
      <add-book-to-item-form
        :studentFrom="student"
        v-if="popupActive"
        :editBook="editBook"
        @closePopup="closePopup"
        @bookSaved="saved"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import addBookToItemForm from "@/components/books/addBookToItemForm.vue";
import deleteBookToStudent from "@/api/books/deleteBookToStudent";
import OrdersWrapper from "@/components/students/orders/wrapper";
import BookItem from "../../books/students/bookItem.vue";

export default {
  components: { addBookToItemForm, OrdersWrapper, BookItem },
  props: ["student_id", "schoolyear"],
  data() {
    return {
      deletedBook: null,
      popupActive: false,
      editBook: {},
    };
  },
  computed: {
    ...mapState({
      books: (state) => state.books.studentBooks,
      student: (state) => state.students.singleStudent,
    }),
  },
  methods: {
    closePopup: function () {
      this.popupActive = false;
      this.editBook = {};
    },
    saved: function (data) {
      const { student_id, schoolyear_id } = data;
      this.reloadBooks({
        student_id,
        schoolyear_id,
      });
    },
    reloadBooks: function ({ student_id, schoolyear_id }) {
      this.$store
        .dispatch("books/getStudentBooksAction", {
          student_id,
          schoolyear_id,
        })
        .then(() => {
          this.closePopup();
          this.$store.dispatch("students/getSingleStudentAction", {
            id: student_id,
          });
        });
    },
    deleteBookFromStudentHandle: function (e, book) {
      this.deletedBook = book;
      this.openAlert();
    },
    openAlert: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete book from group",
        text: "Are you sure you want to delete the book from group?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert: function () {
      const { id, schoolyear_id } = this.deletedBook;
      deleteBookToStudent.call(this, this.student_id, id).then(() =>
        this.$store.dispatch("books/getStudentBooksAction", {
          student_id: this.student_id,
          schoolyear_id,
        })
      );
    },
    editBookHandler: function (group, book_id) {
      const group_data = this.books.find((g) => g.id == group.id);
      if (!group_data.id) return;
      const book = group_data.books.find((b) => b.id == book_id);
      this.editBook = {
        book: book.id,
        selectedGroup: group.id,
        selectedStudents: [this.student_id],
        price: 0,
        schoolyear_id: book.schoolyear_id,
      };
      this.popupActive = true;
    },
  },
};
</script>

<style lang="scss">
.group__item__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.books-group__popup .vs-popup {
  width: 35%;
}
.group-books__items {
  display: flex;
  flex-flow: row;
  margin: 5px 0 3px 45px;
  gap: 10px;
  & > .book-link {
    padding: 3px;
  }
}
.book-lesson-orders,
.group__free-books {
  margin: 15px 0 0 45px;
  h3 {
    margin-bottom: 7px;
  }
}
</style>