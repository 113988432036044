<template>
  <div class="delete-button wrapper" title="delete test result">
    <vs-button
      v-if="size == 'small'"
      type="flat"
      icon="delete"
      @click="deleteHandler"
      color="danger"
      size="small"
    >
    </vs-button>
    <vs-button
      v-else
      type="flat"
      icon="delete"
      @click="deleteHandler"
      color="danger"
      >Delete
    </vs-button>
  </div>
</template>

<script>
export default {
  name: "deleteResultButton",
  props: ["result", "size"],
  methods: {
    deleteHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Delete test result`,
        text: `Are you sure you want to delete test result? This changes can not be canceled.`,
        accept: function () {
          const student_id = this.result.student.id;
          let storeAction = "results/deleteResultsAction";
          if (this.result.type == "TestsIncomeResult") {
            storeAction = "results/deleteIncomeResultsAction";
          }
          this.$store
            .dispatch(storeAction, {
              result_id: this.result.id,
            })
            .then(() => {
              this.$router.push({ name: "Student", params: { student_id } });
            });
        }.bind(this),
      });
    },
  },
};
</script>

<style>
</style>