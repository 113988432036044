<template>
  <div class="student-exclude">
    <vs-button
      type="flat"
      color="primary"
      icon="restart_alt"
      size="small"
      @click="activePrompt = true"
      >Restore</vs-button
    >
    <vs-prompt
      @cancel="insert = new Date()"
      @accept="restoreStudentHandle"
      :active.sync="activePrompt"
      title="Restore student?"
      color="primary"
    >
      <div class="con-exemple-prompt field">
        <label for="">Enter new start date</label>
        <v-date-picker v-model="insert"> </v-date-picker>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  props: ["student_id", "group_id"],
  data() {
    return {
      insert: new Date(),
      activePrompt: false,
    };
  },
  methods: {
    restoreStudentHandle: function () {
      const { insert } = this;
      console.log(insert);
      this.$store
        .dispatch("students/addStudentToGroupAction", {
          studentId: this.student_id,
          groupId: this.group_id,
          insert: insert.getTime() / 1000,
        })
        .then(() => window.location.reload());
    },
  },
};
</script>

<style>
</style>