<template>
  <div class="result__item">
    <div class="fr-1">
      <div class="test-name">
        <h4>{{ result.test.name }}</h4>
        <edit-result-button :result="result" size="small" />
        <delete-result-button :result="result" size="small" />
      </div>
      <result-info :data="result" />
    </div>
    <div class="fr-2">
      <div class="result">
        {{ result.value }}/{{ result.test.max_value || "-" }}
      </div>
      <div
        class="result-persent col-test"
        :class="{
          high: result.persent >= 85,
          subhigh: result.persent >= 75 && result.persent < 85,
          middle: result.persent >= 50 && result.persent < 75,
          low: result.persen < 50,
        }"
      >
        <span class="sub"> {{ result.persent }}% </span>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteResultButton from "./deleteResultButton.vue";
import EditResultButton from "./editResultButton.vue";
import resultInfo from "./resultInfo.vue";

export default {
  components: {
    resultInfo,
    DeleteResultButton,
    EditResultButton,
  },
  props: ["result"],
};
</script>

<style>
</style>