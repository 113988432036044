<template>
  <div class="student-info">
    <div class="label">Age</div>
    <div class="value">{{ student.age }}</div>
    <div class="label">Birthdate</div>
    <div class="value">
      {{ $moment(student.birthdate).format('DD MMMM YYYY') }}
    </div>
    <div class="label">Grade</div>
    <div class="value">{{ student.student_class || '-' }}</div>
    <div class="label">Shift</div>
    <div class="value">{{ student.shift || '-' }}</div>
    <div class="label">School name</div>
    <div class="value">{{ student.school_name || '-' }}</div>
    <div
      class="student-info__marked parents"
      v-if="student.parents && $can('manage', 'students')"
    >
      <div>
        <vs-icon icon="people"></vs-icon>
        Parents
      </div>
      <div v-for="(p, index) in student.parents" :key="index">
        <div class="label">{{ p.type }} - {{ p.name }}</div>
        <div class="value">{{ p.phone }}</div>
        <div class="value" v-if="$can('manage', 'student')">{{ p.desc }}</div>
      </div>
    </div>
    <div
      class="student-info__marked phones"
      v-if="student.phones && $can('manage', 'students')"
    >
      <vs-icon icon="phone"></vs-icon>
      Phones
      <div v-for="(p, index) in student.phones" :key="index">
        <div class="label">
          {{ p.phone }}
          <vs-icon
            icon="star"
            size="small"
            title="Default phone"
            class="default-phone"
            v-if="p.default"
          ></vs-icon>
        </div>
        <div class="value">{{ p.desc }}</div>
      </div>
    </div>
    <div class="student-info__marked notes" v-if="student.notes">
      <div class="value">{{ student.notes }}</div>
    </div>
    <div v-if="$can('manage', 'student')">
      <div class="label">Created</div>
      <div class="value">
        {{ $moment(student.created * 1000).format('DD.MM.YYYY HH:mm') }}
      </div>
      <div class="label" v-if="student.updated">Last update</div>
      <div class="value" v-if="student.updated">
        {{ $moment(student.updated * 1000).format('DD.MM.YYYY HH:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      student: state => state.students.singleStudent,
    }),
  },
}
</script>

<style lang="scss">
.student-info {
  padding: 5px 15px;
  .label {
    margin-top: 15px;
    font-weight: bold;
  }
  .value {
    color: gray;
    margin-top: 2px;
  }
  .student-info__marked {
    margin-top: 7px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #eee;
    margin-top: 1rem;
    box-shadow: 1px 1px 3px 1px #ddd;
    &.parents {
      background-color: rgb(189, 210, 233, 0.3);
    }
    &.phones {
      background-color: rgba(189, 208, 169, 0.3);
    }
  }
  .default-phone {
    opacity: 0.5;
    font-size: 1.3em;
  }
}
</style>
