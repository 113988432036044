<template>
  <div class="surprises-wrapper">
    <div class="surprise__add-button add-button">
      <vs-button icon="star" type="flat" @click="popupActive = true"
        >Add surprise</vs-button
      >
    </div>
    <div class="search"></div>
    <div class="surprises-items">
      <div v-for="s in surprises" :key="s.id">
        <surprisers-item :surprise="s" />
      </div>
    </div>
    <div v-if="popupActive" class="popup">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        title="Add surprise"
        :active.sync="popupActive"
      >
        <surprise-form
          :students="[student]"
          :selectedStudentId="student.id"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SurpriseForm from "./surpriseForm.vue";
import surprisersItem from "./surprisersItem.vue";
export default {
  components: { surprisersItem, SurpriseForm },
  name: "SurprisesWrapper",
  data() {
    return {
      popupActive: false,
    };
  },
  computed: {
    ...mapState({
      surprises: (state) => state.surprises.surprises,
      student: (state) => state.students.singleStudent,
    }),
    student_id: function () {
      return this.$route.params.student_id;
    },
  },
  methods: {
    closePopup: function () {
      this.popupActive = false;
    },
  },
  created() {
    const { student_id } = this;
    this.$store.dispatch("surprises/getSurprisesByStudentAction", {
      student_id,
    });
  },
};
</script>

<style>
.surprises-wrapper {
  padding: 0.5rem 1rem;
}
.con-vs-popup.popup__lessons {
  position: absolute;
  z-index: 20001;
}
</style>