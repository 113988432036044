<template>
  <div class="book-item">
    <vs-icon icon="import_contacts" size="small"></vs-icon>
    <router-link
      :to="{ name: 'Book', params: { book_id: book.id } }"
      class="book-link"
    >
      {{ book.name }}
    </router-link>
    <vs-chip class="book-type" v-if="book.book_in_out_type">{{
      book.book_in_out_type.name
    }}</vs-chip>
    <div
      class="book__price"
      v-if="$can('manage', 'payments') && !free && !hidePrice"
    >
      <div>{{ book.price || 0 }} р.</div>
    </div>
    <div
      class="group-book__manage"
      v-if="$can('manage', 'books') && !free && !notEditable"
    >
      <vs-icon
        icon="edit"
        color="dark"
        size="small"
        @click="editBookHandler"
        title="Edit book"
      ></vs-icon>
    </div>
    <div class="group-book__manage" v-if="$can('manage', 'books')">
      <vs-icon
        icon="close"
        color="dark"
        size="small"
        @click="deleteBookFromStudentHandle"
        title="Delete book"
      ></vs-icon>
    </div>

    <vs-popup
      class="books-group__popup"
      classContent="books__popup-content"
      title="Add book to student"
      :active.sync="popupActive"
      v-if="popupActive"
    >
      <add-book-to-item-form
        :studentFrom="student"
        v-if="popupActive"
        :editBook="editedBook"
        @closePopup="closePopup"
        @bookSaved="saved"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addBookToItemForm from '@/components/books/addBookToItemForm.vue'
import deleteBookToStudent from '@/api/books/deleteBookToStudent'
import deleteBookToGroup from '@/api/books/deleteBookToGroup'

export default {
  props: [
    'book',
    'group_id',
    'free',
    'notEditable',
    'byGroup',
    'byStudent',
    'student_id',
    'hidePrice',
  ],
  components: { addBookToItemForm },
  data() {
    return {
      popupActive: false,
      editedBook: {},
    }
  },
  computed: {
    ...mapState({
      student: state => state.students.singleStudent,
    }),
  },
  methods: {
    editBookHandler: function(e) {
      e.stopPropagation()
      this.editedBook = {
        book: this.book.id,
        selectedGroup: this.group_id,
        selectedStudents: [this.student.id],
        price: 0,
        schoolyear_id: this.book.schoolyear_id,
        bookLinkId: this.book.book_link_id,
      }

      this.popupActive = true
    },
    deleteBookFromStudentHandle: function(e) {
      e.stopPropagation()
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete book from group',
        text: 'Are you sure you want to delete the book from group?',
        accept: () => {
          const { id, schoolyear_id, book_link_id } = this.book
          if (this.byGroup && this.group_id) {
            // в группе удаляем книгу по id
            deleteBookToGroup.call(this, this.group_id, id).then(() =>
              this.$store.dispatch('books/getGroupBooksAction', {
                group_id: this.group_id,
                schoolyear_id,
              })
            )
          } else {
            // в ученике удаляем ссылку на книгу
            deleteBookToStudent
              .call(this, this.student.id, book_link_id)
              .then(() =>
                this.$store.dispatch('books/getStudentBooksAction', {
                  student_id: this.student.id,
                  schoolyear_id,
                })
              )
              .catch(data => {
                this.$vs.notify({
                  title: 'Error',
                  text: data.body.message,
                  color: 'danger',
                  position: 'top-right',
                })
              })
          }
        },
      })
    },
    closePopup: function() {
      this.editedBook = {}
      this.popupActive = false
    },
    saved: function() {
      this.reloadBooks({
        student_id: this.student.id,
        schoolyear_id: this.book.schoolyear_id,
      })
      this.closePopup()
    },
    reloadBooks: function({ student_id, schoolyear_id }) {
      this.$store
        .dispatch('books/getStudentBooksAction', {
          student_id,
          schoolyear_id,
        })
        .then(() => {
          this.$store.dispatch('students/getSingleStudentAction', {
            id: student_id,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.book-item {
  padding: 5px;
  border-radius: 5px;
  background-color: #d7d7d7;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 200px;
  min-height: 2.3rem;
  .vs-icon {
    font-size: 1em;
    width: 1em;
    height: 1em;
  }
  .book-link {
    flex: 1;
  }
  .book__price {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    gap: 5px;
    &:hover {
      box-shadow: 0 0 7px 1px #ccc;
      .book__price-edit {
        display: block;
        transform: translateX(0);
      }
    }
    .book__price-edit {
      display: none;
      transform: translateX(55px);
      transition: all 0.3s;
      text-align: center;
      i {
        font-size: 1em;
        width: 1em;
        height: 1em;
      }
    }
  }
  .group-book__manage {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
    cursor: pointer;
  }
}
</style>
