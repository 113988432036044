<template>
  <div class="surprise">
    <div class="surprise-header">
      <div class="surprise__category" v-if="surprise.category">
        <vs-avatar
          :icon="surprise.category.icon"
          :color="surprise.category.color"
        ></vs-avatar>
        {{ surprise.category.name }}
      </div>
      <div class="surprise-manage" v-if="$can('manage', 'surprises')">
        <vs-button
          size="small"
          type="flat"
          icon="edit"
          color="dark"
          @click="editPopupActive = true"
          >Edit
        </vs-button>
        <vs-button
          size="small"
          type="flat"
          icon="delete"
          color="dark"
          @click="deleteHandler"
        ></vs-button>
      </div>
    </div>
    <div class="surprise__title">
      <h2>{{ surprise.name }}</h2>
      <div class="student">
        <vs-avatar icon="person" size="small"></vs-avatar>
        <span>{{ surprise.student.name }}</span>
      </div>
    </div>
    <div class="surprise__content">
      <div class="surprise__receive">
        <h3>{{ $moment(surprise.received * 1000).format("DD.MM.YYYY") }}</h3>
        <div>Received date</div>
      </div>
      <div class="surprise__giver" v-if="surprise.giver">
        <vs-avatar
          :src="
            surprise.giver && surprise.giver.avatar
              ? `${proxy}/static/files/${surprise.giver.avatar}`
              : undefined
          "
        ></vs-avatar>
        <span class="giver-nickname">{{ surprise.giver.nickname }}</span>
      </div>
      <div class="surprise__stickers">
        <h3>{{ surprise.stickers }}</h3>
        <div>Stickers</div>
      </div>
      <div class="surprise__lesson" v-if="surprise.lesson">
        <h3>
          <vs-avatar
            icon="people"
            :color="surprise.lesson.group.color"
            size="small"
          ></vs-avatar>
          <span>{{ surprise.lesson.group.name }}</span>
          -
          <router-link
            :to="{ name: 'Lessons', params: { lesson_id: surprise.lesson.id } }"
            class="lesson-link"
          >
            {{ surprise.lesson.name }}
          </router-link>
        </h3>
        <div>
          {{ $moment(surprise.lesson.lesson_time * 1000).format("DD.MM.YYYY") }}
        </div>
      </div>
    </div>
    <div class="surprise-desc" v-if="surprise.desc">
      {{ surprise.desc }}
    </div>
    <div class="popup-edit" v-if="editPopupActive">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        :title="`Edit: ${surprise.name}`"
        :active.sync="editPopupActive"
      >
        <surprise-form :surprise="surprise" @closePopup="closePopupHandler" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import SurpriseForm from "./surpriseForm.vue";
export default {
  props: ["surprise"],
  components: { SurpriseForm },
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      editPopupActive: false,
    };
  },
  methods: {
    closePopupHandler: function () {
      this.editPopupActive = false;
    },
    deleteHandler: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete surprise",
        text: "Are you sure you want to delete the surprise?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert: function () {
      const { surprise } = this;
      this.$store
        .dispatch("surprises/deleteSurpriseAction", { surprise })
        .then(() => {
          this.$emit("closePopup");
        });
    },
  },
};
</script>

<style lang="scss">
.surprise {
  padding: 1.25rem;
  color: #e9edf1 transparent;
  .surprise-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .surprise__category {
      flex: 1;
    }
  }
  .surprise__title {
    // display: flex;
    padding: 1.25rem 0.5rem;
    color: #e9edf1 transparent;
    flex-flow: row nowrap;
    align-items: center;

    .student {
      font-size: 1rem;
      color: #7e8299 !important;
      margin-left: -5px;
      margin-top: 5px;
    }
  }
  .surprise__content {
    display: flex;
    flex-flow: row wrap;
    & > div {
      flex-grow: 1;
      width: 33%;
      margin: 5px;
      padding: 1rem;
      border-radius: 10px;
      border: 1px dashed #ccc;
      h3 {
        padding: 3px 0;
      }
      .giver-nickname {
        font-weight: 600;
        padding-left: 0.25rem;
      }
      & > div {
        color: #7e8299 !important;
        font-size: 0.9em;
      }
    }
    .surprise__lesson {
      & > div {
        margin-left: 5px;
      }
    }
  }
  .surprise-desc {
    margin: 5px;
    padding: 1rem;
    background-color: #f2f2f2;
    border-radius: 10px;
  }
}
</style>