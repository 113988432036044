<template>
  <div class="student__result-list">
    <div class="result__add-button">
      <vs-button icon="add" type="flat" @click="popupActive = true">
        Add test result
      </vs-button>
    </div>
    <div class="result-search">
      <vs-input
        icon="search"
        icon-after="true"
        size="large"
        v-model="search"
        placeholder="Search test result..."
      ></vs-input>
    </div>

    <div
      class="results-group"
      v-for="(value, key) in groupedResults"
      :key="key"
    >
      <h3 class="result-group__title">{{ key }}</h3>
      <div class="result-group__item" v-for="r in value" :key="r.id">
        <router-link
          :to="{ name: 'Result', params: { result_id: r.id } }"
          target="_blank"
          v-if="r.type == 'TestsResult'"
        >
          <result-item :result="r" />

          <!-- <result-item :result="r" v-else-if="r.type == 'TestsIncomeResult'" /> -->
        </router-link>
        <router-link
          :to="{
            name: 'IncomeResult',
            params: { result_id: r.id },
          }"
          target="_blank"
          v-else-if="r.type == 'TestsIncomeResult'"
        >
          <income-result-item
            :result="r"
            @incomeResultSend="incomeResultSendHandler"
          />
        </router-link>
      </div>
    </div>
    <div v-if="popupActive">
      <vs-popup
        class="form-popup result__popup"
        classContent="result__popup-content"
        title="Student"
        :active.sync="popupActive"
        v-if="popupActive"
      >
        <ResultForm :student="student" @closePopup="closePopup" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import resultItem from "../../results/resultItem.vue";
import ResultForm from "@/components/results/form.vue";
import IncomeResultItem from "../../results/incomeResultItem.vue";

export default {
  components: { resultItem, ResultForm, IncomeResultItem },
  name: "studentResultList",
  props: ["student_id"],
  data() {
    return {
      search: "",
      popupActive: false,
    };
  },
  computed: {
    ...mapState({
      results: (state) => state.results.results,
      incomeTestsResults: (state) => state.results.incomeTestsResults,
      student: (state) => state.students.singleStudent,
    }),
    groupedResults: function () {
      const groups = {};
      this.filteredResults.forEach((r) => {
        let group = "";
        if (r.group?.schoolyear) {
          group = r.group.schoolyear;
        } else if (r.type == "TestsIncomeResult") {
          group = "Testing";
        }

        if (groups[group] === undefined) {
          groups[group] = [];
        }
        groups[group].push(r);
      });
      return groups;
    },
    filteredResults: function () {
      const allResults = [...this.results, ...this.incomeTestsResults];
      allResults.sort((a, b) => {
        return b.created - a.created;
      });
      if (this.search) {
        const results = allResults.filter(
          (r) =>
            (r.test.name &&
              r.test.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (r.book?.name &&
              r.book.name.toLowerCase().includes(this.search.toLowerCase())),
        );
        return results;
      }
      return allResults;
    },
  },
  created: function () {
    this.getTestResults();
  },
  methods: {
    getTestResults: async function () {
      const { student_id } = this;
      await this.$store.dispatch("results/getResultsByStudentAction", {
        student_id,
      });
      await this.$store.dispatch(
        "results/getIncomeTestsResultsByStudentAction",
        { student_id },
      );
    },
    closePopup: function () {
      this.getTestResults();
      this.popupActive = false;
    },
    incomeResultSendHandler: async function (result_id) {
      const sended = new Date().getTime() / 1000;
      await this.$store
        .dispatch("results/sendIncomeResultAction", { result_id, sended })
        .then(() => {
          this.$vs.notify({
            title: "Success",
            text: `Tests mark as sended`,
            color: "success",
            position: "top-right",
          });
        });
      await this.$store.dispatch(
        "results/getIncomeTestsResultsByLessonAction",
        {
          lesson_id: this.lesson.id,
        },
      );
    },
  },
};
</script>

<style lang="scss">
.student__result-list {
  & > div {
    margin: 10px 0;
  }
  .result-search {
    &:before {
      content: "";
      display: block;
      clear: both;
    }
    .vs-input {
      margin-top: 7px;
      width: 100%;
    }
  }
  .results-group {
    .result-group__title {
      margin-bottom: 15px;
    }
  }
  .result__item {
    padding: 10px 15px;
    border: 1px solid var(--element-border-color);
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row nowrap;
    color: var(--font-color);
    transition: all 0.2s;
    &:hover {
      box-shadow: 2px 2px 6px 2px #eee;
      border-color: var(--simple-translate-highlight);
      & .manage-buttons {
        display: block !important;
      }
    }
    &:hover .manage-buttons {
      display: block !important;
    }
    &.testing {
      background-color: var(--testing-bcg-color);
      .fr-2 {
        .result {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
        }
        .result__label {
          font-size: 1.1rem;
          color: var(--font-color);
          margin-right: 0.5rem;
        }
      }
    }
    .fr-1 {
      flex: 1;
      .test-name {
        h4 {
          line-height: 1.5em;
          height: 1.1em;
          margin-right: 0.8rem;
        }
        // font-weight: 400;
        font-size: 1.2em;
        margin-bottom: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
    .fr-2 {
      width: 25%;
      text-align: right;
      .result {
        font-weight: 400;
        font-size: 1.2em;
        vertical-align: middle;
      }
      .danger {
        color: var(--font-negative-color);
      }
      .success {
        color: var(--font-positive-color);
      }
      .manage-buttons {
        display: none;
        & > .wrapper {
          display: inline-block;
          margin-top: 7px;
        }
        button {
          float: none;
        }
      }
    }
  }
}
</style>