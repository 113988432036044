<template>
  <div
    class="result__item"
    :class="{ testing: result.type == 'TestsIncomeResult' }"
  >
    <div class="fr-1">
      <div class="test-name">
        <h4>Entry</h4>
        <edit-result-button :result="result" size="small" />
        <delete-result-button :result="result" size="small" />
      </div>
      <result-info :data="result" />
    </div>
    <div class="fr-2">
      <div class="result">
        <span class="result__label">Oral:</span>
        <span class="result__value">{{ result.oral_value || "-" }}</span>
      </div>
      <div class="result">
        <span class="result__label">Written:</span>
        <span class="result__value">{{ result.written_value || "-" }}</span>
      </div>
      <div class="result" v-if="$can('income_result_send')">
        <div class="result sended" v-if="result.sended">
          <span class="result__label"> Sended: </span>
          <vs-icon icon="done" color="success"></vs-icon>
        </div>
        <div class="result" v-else>
          <vs-button
            icon="send"
            icon-after
            color="primary"
            type="flat"
            size="small"
            @click="sendResultHandler($event, result.id)"
            >Send</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteResultButton from "./deleteResultButton.vue";
import EditResultButton from "./editResultButton.vue";
import resultInfo from "./resultInfo.vue";

export default {
  components: {
    resultInfo,
    DeleteResultButton,
    EditResultButton,
  },
  props: ["result"],
  methods: {
    sendResultHandler: function (e, result_id) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("incomeResultSend", result_id);
    },
  },
};
</script>

<style>
</style>