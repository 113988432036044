<template>
  <div class="order-form">
    <div class="field">
      <label for="">Value*</label>
      <vs-input v-model.number="value" required></vs-input>
    </div>
    <div class="field">
      <label for="">Lessons plan</label>
      <vs-input v-model.number="lessons_plan"></vs-input>
    </div>
    <div class="field">
      <label for="">Comment</label>
      <vs-textarea v-model="comment" counter="500"></vs-textarea>
    </div>
    <div class="buttons">
      <vs-button icon="save" @click="submitForm" :disabled="buttonDisabled"
        >Save</vs-button
      >

      <vs-button
        icon="restart_alt"
        type="flat"
        @click="resetOrderHandler"
        color="dark"
        v-if="order.is_edited"
        :disabled="buttonDisabled"
        >Restore</vs-button
      >
      <vs-button
        icon="close"
        type="flat"
        @click="$emit('closePopup')"
        :disabled="buttonDisabled"
        >Cancel</vs-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["order"],
  data() {
    return {
      value: this.order.value,
      lessons_plan: this.order.lesson_cur_plan,
      comment: this.order.comment,
      buttonDisabled: false,
    };
  },
  methods: {
    submitForm: function () {
      const order_id = this.order.id;
      const { value, lessons_plan, comment } = this;
      this.buttonDisabled = true;
      this.$store
        .dispatch("orders/saveStudentGroupOrderAction", {
          order_id,
          data: { value, lessons_plan, comment },
        })
        .then(() => {
          this.$emit("saveOrder");
        });
    },
    resetOrderHandler: function () {
      const order_id = this.order.id;
      this.buttonDisabled = true;
      this.$store
        .dispatch("orders/resetSaveStudentGroupOrderAction", {
          order_id,
        })
        .then(() => {
          this.$emit("saveOrder");
        });
    },
  },
};
</script>

<style>
</style>