<template>
  <div class="students-testing">
    <vs-tabs>
      <vs-tab label="By Grade" class="by-grade">
        <testingByGradeVue :data="data" />
      </vs-tab>
      <vs-tab label="By Shift" class="by-shift">
        <testingByShiftVue :data="data" />
      </vs-tab>
      <vs-tab label="All" class="all">
        <TestingNoGroup :data="data" />
      </vs-tab>
    </vs-tabs>
    <div v-if="isGroupByGrade"></div>
  </div>
</template>

<script>
import testingByGradeVue from './testing/testingByGrade.vue'
import testingByShiftVue from './testing/testingByShift.vue'
import TestingNoGroup from './testing/testingNoGroup.vue'

export default {
  props: ['data', 'statusID'],
  data() {
    return {
      isGroupByGrade: true,
      isGroupByShift: false, // группировка по смене
    }
  },
  methods: {
    doubleClickHandler: function(tr) {
      this.$router.push({ name: 'Student', params: { student_id: tr.id } })
    },
    handleSearch(searching) {
      this.$emit('searchStudent', searching)
    },
  },
  components: { testingByGradeVue, testingByShiftVue, TestingNoGroup },
}
</script>

<style lang="scss" scoped></style>
