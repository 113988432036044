<template>
  <div class="testing-grade">
    <div
      class="testing-grade__item"
      v-for="(item, key) in grouppedStudentList"
      :key="key"
    >
      <div class="testing-grade__name">
        <h3 v-if="!key">Empty Grade</h3>
        <h3 v-else>Grade {{ key }}</h3>
      </div>
      <div class="shifts">
        <div class="shift" v-for="(shift, shiftKey) in item" :key="shiftKey">
          <h4 v-if="shiftKey === 'null'">Empty Shift</h4>
          <h4 v-else>Shift {{ shiftKey }}</h4>
          <TestingTable :items="Object.values(shift)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestingTable from './testingTable.vue'

export default {
  props: ['data'],
  components: { TestingTable },
  computed: {
    grouppedStudentList: function() {
      let output = []
      output = this.data.reduce((out, item) => {
        let itemData = [item]
        // берем класс
        let grade = out[item.student_class] || {}
        grade[item.shift] = [...(grade[item.shift] || []), ...itemData]
        grade = Object.keys(grade)
          .sort()
          .reduce((out, itemKey) => {
            out[itemKey] = grade[itemKey]
            return out
          }, {})
        // Обновляем/добавляем класс в итоговый объект
        out[item.student_class] = { ...out[item.student_class], ...grade }
        return out
      }, {})
      output = Object.keys(output)
        .sort()
        .reduce((out, itemKey) => {
          out[itemKey] = output[itemKey]
          return output
        }, {})
      return output || this.data
    },
  },
}
</script>

<style lang="scss">
.shifts {
  //   display: flex;
  //   flex-flow: row nowrap;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .shift {
    margin: 0.3rem 0.5rem 0 0.5rem;
  }
}
</style>

<!-- 
          // проверяем по сменам, добавляем / обновляем учеников в смене

grade[item.shift] = [...(grade[item.shift] || []), ...itemData]
          // сортируем смены
          grade = Object.keys(grade)
            .sort()
            .reduce((out, itemKey) => {
              out[itemKey] = grade[itemKey]
              return out
            }, {}) -->
