<template>
  <div>
    <div class="orders__header">
      <vs-button
        type="flat"
        icon="refresh"
        color="dark"
        size="small"
        title="Refresh"
        @click="reloadHandle"
        >Refresh</vs-button
      >
      <vs-button type="flat" size="small">Export *.xls</vs-button>
      <vs-dropdown vs-trigger-click class="recalculate">
        <a class="recalculate-title" href.prevent>
          Recalculate from
          <vs-icon class="" icon="expand_more" size="20px"></vs-icon>
        </a>

        <vs-dropdown-menu>
          <vs-dropdown-item
            v-for="(order, indextr) in orders"
            :key="indextr"
            @click="recalcHandle($event, order.id, order.student.id)"
          >
            {{
              $moment()
                .year(order.year)
                .month(order.month - 1)
                .format('MMMM')
            }}
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div class="order__table-wrapper">
      <table class="order__table" id="order-table">
        <thead>
          <th></th>
          <th sort-key="month">Month</th>
          <th>Lessons plan</th>
          <th sort-key="order_group_value">Group (rub)</th>
          <th>Lessons prev fact</th>
          <th>Current Balance</th>
          <th>Discount</th>
          <th sort-key="plan_value">Plan (rub)</th>
          <th sort-key="value">To Pay</th>
          <th>Date</th>
          <th>Paid</th>
          <th>End Balance</th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr
            v-for="(tr, indextr) in orders"
            :key="indextr"
            @click="openPayments($event, tr)"
          >
            <td>
              <vs-button
                icon="edit"
                color="dark"
                title="Edit order"
                size="small"
                type="flat"
                @click="editOrderHandler($event, tr)"
              ></vs-button>
            </td>
            <td>
              <span>
                {{
                  $moment()
                    .year(tr.year)
                    .month(tr.month - 1)
                    .format('MMMM')
                }}
              </span>
            </td>
            <td>
              {{ tr.lesson_cur_plan }}
            </td>
            <td>{{ tr.order_group_value }} р.</td>
            <td>{{ tr.lesson_prev_fact }}</td>
            <td
              class="credit highlight"
              :class="{ negative: tr.balance_start < 0 }"
            >
              {{ tr.balance_start }} р.
            </td>
            <td
              class="discount"
              :class="{
                d15: tr.discount == 15,
                d25: tr.discount == 25,
                d50: tr.discount == 50,
              }"
            >
              <div>{{ tr.discount_value }} р.</div>
              <div>({{ tr.discount || '0' }} %)</div>
            </td>
            <td
              class="discount"
              :class="{
                d15: tr.discount == 15,
                d25: tr.discount == 25,
                d50: tr.discount == 50,
              }"
            >
              {{ tr.value_plan || '-' }} р.
            </td>
            <td
              class="discount"
              :class="{
                d15: tr.discount == 15,
                d25: tr.discount == 25,
                d50: tr.discount == 50,
              }"
            >
              {{ tr.value || '-' }} р.
              <div class="order-status status-block" v-if="tr.is_edited">
                <span class="status"> edited </span>
              </div>
            </td>
            <td>
              <span v-if="tr.last_payment_date">
                {{
                  $moment(tr.last_payment_date * 1000).format('DD.MM.YY HH:mm')
                }}
              </span>
            </td>
            <td class="highlight">{{ tr.payments }} р.</td>
            <td class="credit highlight" :class="{ negative: tr.balance < 0 }">
              {{ $round(tr.balance) }} р.
            </td>
            <td>
              <order-send-button
                :order="tr"
                @setOrderAsSended="setOrderAsSended"
              />
            </td>
            <td>
              <vs-tooltip
                title="order"
                :text="
                  `id: ${tr.id};
                owner: ${tr.owner.nickname};
                editor: ${tr.user ? tr.user.nickname : ' - '};
                created: ${$moment(tr.created * 1000).format('DD.MM.YY HH:mm')};
                updated: ${$moment(tr.updated * 1000).format('DD.MM.YY HH:mm')} 
                `
                "
              >
                <vs-icon icon="error_outline" size="16px"></vs-icon>
              </vs-tooltip>
              <vs-icon
                icon="comment"
                :title="tr.comment"
                v-if="tr.comment && tr.comment != 'None'"
                color="primary"
                size="1rem"
              ></vs-icon>
            </td>
          </tr>
          <tr v-if="groupOrdersStat">
            <td></td>
            <td class="highlight">Amounts:</td>
            <td></td>
            <td>{{ groupOrdersStat.orders_groups_sum }} р.</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ groupOrdersStat.orders_students_sum }} р.</td>
            <td></td>
            <td></td>
            <td class="highlight">{{ groupOrdersStat.payments_sum }} р.</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <vs-popup
      class="form-popup payment__popup"
      classContent="payment__popup-content"
      :title="popuptTitle"
      :active.sync="popupActive"
    >
      <PaymentWrapper
        v-if="popupActive"
        :student="student"
        :group="group"
        :order="selectedOrder"
        @changedPayments="changedPayments"
      >
        <template v-slot:order>
          <div class="order-info">
            <span>Order #{{ selectedOrder.id }}</span>
            <span>
              <vs-icon icon="query_builder" />
            </span>
            <span class="highlight">{{
              $moment()
                .year(selectedOrder.year)
                .month(selectedOrder.month)
                .format('MMMM YYYY')
            }}</span>
            <span
              ><vs-chip>
                <vs-avatar icon="people" :color="selectedOrder.group.color" />
                Group {{ selectedOrder.group.name }}
              </vs-chip></span
            >
            <span
              ><vs-chip>
                <vs-avatar icon="person" />
                {{ selectedOrder.student.name }}
              </vs-chip></span
            >
            <span class="highlight">{{ selectedOrder.value }}р.</span>
            <span
              class="credit highlight"
              :class="{ negative: selectedOrder.current_credit < 0 }"
              >{{ selectedOrder.current_credit }}р.</span
            >
          </div>
        </template>
      </PaymentWrapper>
    </vs-popup>
    <vs-popup
      class="form-popup edit-order__popup"
      classContent="edit-oerder__popup-content"
      :title="`Edit order ${editedOrder.id}`"
      :active.sync="editPopupActive"
      v-if="editPopupActive"
    >
      <student-group-order-form
        :order="editedOrder"
        @saveOrder="saveOrderHandler"
        @closePopup="closePopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import recalcStudentOrder from '@/api/orders/recalcStudentOrder.js'
import sendOrderStudents from '@/api/orders/sendOrderStudents.js'
import PaymentWrapper from '@/components/payments/singleForm/wrapper'
import OrderSendButton from '../../orders/orderSendButton.vue'
import StudentGroupOrderForm from '../../orders/studentGroupOrderForm.vue'

export default {
  props: ['student', 'group', 'existOrders', 'orderType'],
  data() {
    return {
      popupActive: false,
      editPopupActive: false,
      editedOrder: null,
      popuptTitle: 'Payments',
      selectedOrder: {},
      changed: false,
    }
  },
  components: {
    PaymentWrapper,
    OrderSendButton,
    StudentGroupOrderForm,
  },

  computed: {
    ...mapState({
      ordersByStudent: state => state.orders.ordersByStudent,
      ordersStat: state => state.orders.ordersStat,
    }),
    orders: function() {
      return this.ordersByStudent[this.student.id]
        ? this.ordersByStudent[this.student.id][this.group.id]
        : []
    },
    groupOrdersStat: function() {
      return this.ordersStat[this.student.id]
        ? this.ordersStat[this.student.id][this.group.id]
        : {}
    },
  },
  methods: {
    openPayments: function(e, tr) {
      e.preventDefault()
      this.popupActive = true
      this.popuptTitle = `Payments to order #${tr.id}`
      this.selectedOrder = tr
    },
    changedPayments: function() {
      this.reloadHandle()
    },
    sendHandle: function(e, id) {
      e.preventDefault()
      e.stopPropagation()
      const data = {
        id,
        sended: true,
      }
      sendOrderStudents.call(this, { data }).then(data => {
        const index = this.orders.findIndex(o => o.id == data.data.id)
        if (index != -1) this.orders.splice(index, 1, data.data)
        this.$vs.notify({
          title: 'Order marked as sended',
          text: ``,
          color: 'success',
          position: 'top-right',
        })
      })
    },
    setOrderAsSended: function(order) {
      const index = this.orders.findIndex(o => o.id == order.id)
      if (index != -1) this.orders.splice(index, 1, order)
    },
    reloadHandle: function() {
      this.$store.dispatch('orders/getOrdersGroupsByStudentAction', {
        student: this.student.id,
        groups: [this.group.id],
        schoolyear_id: this.group.schoolyear.id,
      })
    },
    recalcHandle: function(e, id, student_id) {
      e.preventDefault()

      this.$vs.loading({
        container: '#order-table',
        scale: 0.6,
      })

      const data = {
        id,
        student_id,
      }

      recalcStudentOrder.call(this, { data }).then(response => {
        if (response.status == 204) {
          this.reloadHandle()
          this.$vs.notify({
            title: 'Recalculate success',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        } else {
          this.$vs.notify({
            title: 'Error in recalculate orders',
            text: ``,
            color: 'danger',
            position: 'top-right',
          })
        }
        this.$vs.loading.close('#order-table > .con-vs-loading')
      })
    },
    saveOrderHandler: function() {
      this.reloadHandle()
      this.editedOrder = null
      this.editPopupActive = false
    },
    editOrderHandler: function(e, order) {
      e.preventDefault()
      e.stopPropagation()
      this.editedOrder = order
      this.editPopupActive = true
    },
    closePopup: function() {
      this.editedOrder = null
      this.editPopupActive = false
    },
  },
}
</script>

<style lang="scss">
.order__table {
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  position: relative;
  th {
    vertical-align: top;
  }
  td,
  th {
    padding: 7px;
    text-align: left;
    font-size: 0.8em;
    max-width: 100px;
  }
  th {
    word-wrap: break-word;
    border-bottom: 1px solid #ccc;
  }
  tr {
    cursor: pointer;
    transition: 0.2s all;
    &:nth-child(2n),
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
.orders__header {
  margin-bottom: 7px;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.order-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > * {
    margin-right: 15px;
  }
  .con-vs-chip {
    float: none !important;
  }
}
.recalculate {
  border-radius: 5px;
  .recalculate-title {
    padding: 5px;
    font-size: 0.9em;
    display: block;
  }
  &:hover {
    background-color: #eee;
  }
}
.order-status {
  background-color: rgba(var(--vs-warning));
}
</style>
