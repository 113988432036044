<template>
  <vs-table
    v-model="selected"
    search
    @dblSelection="doubleClickHandler"
    :data="data"
    :hoverFlat="true"
    @search="handleSearch"
  >
    <template slot="header">
      <h3>Students ({{ itemsCount }})</h3>
    </template>
    <template slot="thead">
      <vs-th sort-key="name">Name</vs-th>
      <vs-th sort-key="student_class">Grade</vs-th>
      <vs-th v-if="statusID == 1">Shift</vs-th>
      <vs-th sort-key="age">Age</vs-th>
      <vs-th v-if="$can('manage', 'students')">Mother's phone</vs-th>
      <vs-th v-if="$can('manage', 'students')">Phone</vs-th>
      <vs-th></vs-th>
      <vs-th v-if="statusID == 1">Queue comment</vs-th>
      <vs-th v-if="statusID == 1">Test comment</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td
          :data="data[indextr].name"
          :class="{ 'is-has-relative': data[indextr].is_has_relative }"
        >
          <vs-icon icon="person" />
          <span class="student-name">{{ data[indextr].name }}</span>
        </vs-td>

        <vs-td :data="data[indextr].student_class">{{
          data[indextr].student_class
        }}</vs-td>
        <vs-td :data="data[indextr].shift" v-if="statusID == 1">{{
          data[indextr].shift
        }}</vs-td>

        <vs-td :data="data[indextr].age">{{ data[indextr].age }}</vs-td>
        <vs-td :data="data[indextr].parents" v-if="$can('manage', 'students')">
          <div
            v-if="data[indextr].parents"
            :set="
              (mother = data[indextr].parents.find(p => p.type == 'mother'))
            "
          >
            <span v-if="mother">
              {{ mother.phone }}
            </span>
          </div>
        </vs-td>

        <vs-td :data="data[indextr].phones" v-if="$can('manage', 'students')">
          <div v-for="(phone, index) in data[indextr].phones" :key="index">
            <div class="students__list__phone" v-if="phone.default">
              {{ phone.phone }} - {{ phone.desc }}
            </div>
          </div>
          <div
            :set="
              (phones = data[indextr].phones.filter(p => !p.default).length)
            "
          >
            <div v-if="phones">(+{{ phones }})</div>
          </div>
        </vs-td>
        <vs-td :data="data[indextr].id" class="manage-students">
          <div class="manage-buttons">
            <vs-button
              v-if="$can('manage', 'student') && [2, 4].includes(statusID)"
              :data-id="data[indextr].id"
              color="dark"
              icon="west"
              type="flat"
              size="small"
              @click="eventBus.$emit('studentToQueue', data[indextr].id)"
              >To Queue</vs-button
            >
            <vs-button
              v-if="$can('manage', 'student') && [1, 3, 4].includes(statusID)"
              :data-id="data[indextr].id"
              color="dark"
              :icon="statusID > 2 ? 'west' : 'east'"
              type="flat"
              @click="eventBus.$emit('studentToTesting', data[indextr].id)"
              size="small"
              >To Testing</vs-button
            >
            <vs-button
              v-if="$can('manage', 'student') && [1].includes(statusID)"
              :data-id="data[indextr].id"
              color="#5b3cc4"
              :icon="statusID > 2 ? 'west' : 'east'"
              type="flat"
              @click="eventBus.$emit('studentToTrial', data[indextr].id)"
              size="small"
              >To Trial</vs-button
            >
            <vs-button
              v-if="$can('manage', 'student') && [1, 2, 4].includes(statusID)"
              :data-id="data[indextr].id"
              color="primary"
              :icon="statusID == 4 ? 'west' : 'east'"
              type="flat"
              @click="eventBus.$emit('studentToActive', data[indextr].id)"
              size="small"
              >To Active</vs-button
            >
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="visibility"
              type="flat"
              @click="
                $router.push({
                  name: 'Student',
                  params: { student_id: data[indextr].id },
                })
              "
            ></vs-button>
            <vs-button
              v-if="$can('manage', 'student')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="edit"
              type="flat"
              @click="eventBus.$emit('editStudent', data[indextr].id)"
            ></vs-button>
            <vs-button
              v-if="$can('manage', 'student')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="delete"
              type="flat"
              @click="eventBus.$emit('deleteStudent', data[indextr].id)"
            ></vs-button>
          </div>
        </vs-td>
        <vs-td v-if="statusID == 1" class="queue-note">
          <span
            v-html="data[indextr].queue_note"
            @click="eventBus.$emit('editStudent', data[indextr].id)"
            class="queue-note-block"
            title="Edit"
            v-if="data[indextr].queue_note"
          ></span>
        </vs-td>
        <vs-td v-if="statusID == 1">
          <div
            v-for="(testResult, index) in data[indextr].testing_results"
            :key="index"
          >
            <span v-html="testResult.comment"></span>
          </div>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import eventBus from '@/eventBus.js'

export default {
  props: ['data', 'statusID', 'itemsCount'],
  data() {
    return {
      selected: [],
      selectedId: null,
      searchText: '',
      eventBus: eventBus,
    }
  },
  methods: {
    handleSearch(searching) {
      this.$emit('searchStudent', searching)
    },
    doubleClickHandler: function(tr) {
      this.$router.push({ name: 'Student', params: { student_id: tr.id } })
    },
  },
}
</script>

<style lang="scss">
.queue-note {
  width: 200px;
}
.queue-note-block {
  padding: 0.5rem;
  display: block;
  border-radius: 0.5rem;
  &:hover {
    background-color: var(--table-disabled-bcg);
  }
}
</style>
