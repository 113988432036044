import Vue from 'vue'

export default function deleteBookToStudent(student_id, book_link_id) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/books/" + book_link_id + '/student/' + student_id
    return Vue.http
        .delete(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch((e) => { 
            throw e 
        })
}