<template>
  <div class="student-exclude">
    <vs-button
      type="flat"
      color="danger"
      icon="close"
      size="small"
      @click="activePrompt = true"
      >Exclude</vs-button
    >
    <vs-prompt
      @cancel="exit = new Date()"
      @accept="exludeStudentHandle"
      :active.sync="activePrompt"
      title="Exclude student?"
      color="danger"
    >
      <div class="con-exemple-prompt field">
        <label for="">Enter exclude date</label>
        <v-date-picker v-model="exit"> </v-date-picker>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  props: ['student_id', 'group'],
  data() {
    return {
      exit: new Date(),
      activePrompt: false,
    }
  },
  methods: {
    exludeStudentHandle: function() {
      const { exit } = this
      const data = {
        studentId: this.student_id,
        groupId: this.group.id,
        exit: exit.getTime() / 1000,
      }
      if (this.group.is_trial) {
        data.comment = `<b>Exclude student from Group ${this.group.name}.</b><p>Reason: was on trial</p>`
        data.is_trial = this.group.is_trial
      }
      this.$store
        .dispatch('students/excludeStudentFromGroupAction', data)
        .then(() => window.location.reload())
    },
  },
}
</script>

<style></style>
