<template>
  <div class="students__form">
    <vs-row>
      <vs-col>
        <h3>General</h3>
        <vs-input size="large" label="First name" v-model="first_name">
        </vs-input>
        <vs-input size="large" label="Last name" v-model="last_name"></vs-input>
        <vs-input
          size="large"
          label="Middle name"
          v-model="middle_name"
        ></vs-input>
        <div class="student__status">
          <div class="student__status__select">
            <vs-select
              placeholder="Status"
              class="groups__select"
              label="Status"
              v-model="status"
            >
              <vs-select-item key="-1" value="-1" text="-- empty" />
              <vs-select-item key="1" value="1" text="Queue" />
              <vs-select-item key="2" value="2" text="Testing" />
            </vs-select>
          </div>
          <div class="student_is_has_relative">
            <vs-checkbox v-model="is_has_relative">
              Свой
            </vs-checkbox>
          </div>
        </div>
        <hr />
        <div class="student-grade">
          <vs-row>
            <vs-col>
              <div id="school">
                <vs-input
                  size="large"
                  label="School"
                  v-model="school_name"
                ></vs-input>
              </div>
            </vs-col>
            <vs-col
              ><div id="class">
                <vs-input
                  size="large"
                  label="Grade"
                  v-model="student_class"
                ></vs-input></div
            ></vs-col>
            <vs-col
              ><div id="shift">
                <vs-input
                  size="large"
                  label="Shift"
                  v-model="shift"
                ></vs-input></div
            ></vs-col>
          </vs-row>
        </div>
        <div id="birthdate">
          <label class="birhdate__placeholder">Birthdate</label>
          <v-date-picker v-model="birthdate">
            <template v-slot="{ inputValue, inputEvents }">
              <div>
                <vs-input
                  class="calendar-input"
                  :value="`${inputValue}`"
                  v-on="inputEvents"
                  size="large"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <hr />
        <h3>Notes</h3>
        <vs-textarea label="Queue note" v-model="queue_note" />
        <vs-textarea label="Student Note, max: 500" v-model="notes" />
      </vs-col>
      <vs-col>
        <div class="student-parents">
          <h3>Parents</h3>
          <div
            class="student-parents__item list-item"
            v-for="(parent, index) in parents"
            :key="index"
          >
            <h4>
              {{ parent.type | capitalize }}
              <span v-if="!parent.opened">{{ parent.name || '-' }}</span>
            </h4>

            <div class="student-parents__inputs">
              <vs-input
                size="large"
                label="Name"
                v-model="parents[index].name"
              ></vs-input>
              <vs-input
                size="large"
                label="Phone"
                v-model="parents[index].phone"
              ></vs-input>
            </div>

            <vs-textarea label="Description" v-model="parents[index].desc" />
          </div>
        </div>
        <div class="students-phones">
          <h3>Phones</h3>
          <div
            class="student-phones__item list-item"
            v-for="(phone, index) in phones"
            :key="index"
          >
            <vs-button
              icon="delete"
              type="flat"
              color="dark"
              @click="delPhone(index)"
            ></vs-button>
            <vs-input
              size="large"
              label="Phone"
              v-model="phones[index].phone"
            ></vs-input>
            <vs-input
              size="large"
              label="Description"
              v-model="phones[index].desc"
            ></vs-input>
            <vs-checkbox v-model="phones[index].default"
              >Show phone in list</vs-checkbox
            >
          </div>
          <div class="students__add-phone__button">
            <vs-button icon="add" type="flat" @click="addPhone"
              >Add phone</vs-button
            >
          </div>
        </div>
      </vs-col>
    </vs-row>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveStudent">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentForm',
  props: ['student', 'groupsList', 'students'],
  data() {
    let data = {
      first_name: '',
      last_name: '',
      middle_name: '',
      student_class: '',
      is_has_relative: false,
      shift: '',
      school_name: '',
      birthdate: null,
      parents: null,
      notes: null,
      queue_note: null,
      phones: [],
      groups: [],
      status: '',
      defaultGroup: {
        id: '',
        discount: '15',
      },
      defaultPhone: {
        phone: '',
        desc: '',
        default: false,
        index: 0,
      },
      defaultParents: [
        {
          type: 'mother',
          name: '',
          phone: '',
          desc: '',
        },
        {
          type: 'father',
          name: '',
          phone: '',
          desc: '',
        },
      ],
    }

    if (this.student.id) {
      data = { ...data, ...this.student }
      data.groups = data.groups.map(g => {
        return {
          ...g.group,
          discount: g.discount,
        }
      })
    }

    if (!data.parents || !data.parents.length)
      data.parents = [...data.defaultParents]

    data.findedStudents = ''
    return { ...data }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  methods: {
    saveStudent: function() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        student_class: this.student_class,
        shift: this.shift,
        school_name: this.school_name,
        birthdate: this.birthdate ? this.birthdate.getTime() / 1000 : null,
        parents: this.parents,
        notes: this.notes,
        queue_note: this.queue_note,
        phones: this.phones,
        status: parseInt(this.status, 10),
        is_has_relative: this.is_has_relative,
      }
      if (this.id) data.id = this.id
      this.$store
        .dispatch('students/saveStudentsAction', {
          student: data,
        })
        .then(() => {
          this.$vs.notify({
            title: 'Student saved',
            text: `Student ${this.student.name} saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$emit('closePopup')
          this.$emit('updateStudents')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error on save student',
            text: '',
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    addGroup: function() {
      let key = this.groups.length
      const newDefaultValue = { ...this.defaultGroup }
      newDefaultValue.key = key
      this.groups.push(newDefaultValue)
    },
    delGroup: function(index) {
      this.groups.splice(index, 1)
    },
    addPhone: function() {
      if (this.phones == null) this.phones = []
      let key = this.phones.length
      const newDefaultValue = { ...this.defaultPhone }
      newDefaultValue.index = key
      if (key == 0) newDefaultValue.default = true
      this.phones.push(newDefaultValue)
    },
    delPhone: function(index) {
      this.phones.splice(index, 1)
    },
  },
  watch: {
    first_name: function() {
      const findedStudents = this.students
        .filter(
          s =>
            s.name.toLowerCase().indexOf(this.first_name.toLowerCase()) !== -1
        )
        .map(s => s.name)
      if (findedStudents.length == this.students.length) {
        this.findedStudents = ''
        return
      }
      this.findedStudents = findedStudents.join(', </br>')
    },
  },
}
</script>

<style lang="scss">
.students__form {
  padding: 0 5px;
  .vs-input {
    width: 100%;
    margin-top: 0.8rem;
    // .vs-input--input {
    //   padding: 10px;
    // }
  }
  & > .vs-row {
    // gap: 1rem;
    flex-flow: row nowrap;
    & > .vs-col {
      flex: 1;
      padding: 1rem;
      margin: 0 1rem;
      box-shadow: 0 0 10px 1px #ddd;
      border-radius: 0.5rem;
    }
  }
  .student-grade {
    margin-bottom: 1rem;
    .vs-row {
      gap: 5px;
      flex-flow: row nowrap;
    }
  }
  hr {
    margin: 25px 0;
    color: #eee;
  }
  .student__status {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    gap: 1rem;
  }
  .student-groups {
    clear: both;
    .students__add-group__button {
      button {
        margin-bottom: 15px;
      }
    }
    .student-groups__item {
      margin: 10px 0;
      padding: 15px 10px;
      background-color: #f1f1f1;
      border-radius: 5px;
      box-shadow: 0 0 3px 1px #ccc;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      & > div {
        // display: inline-block;
        margin-right: 5px;
      }
      &:last-child() {
        margin-bottom: 25px;
      }
    }
  }
  .list-item {
    padding: 0.5rem;
    border-radius: 5px;
    & > div {
      display: inline-block;
      margin-right: 5px;
    }
    &:last-child() {
      margin-bottom: 25px;
    }
  }
  .students-phones {
    .student-phones__item {
      padding-top: 25px;
      .vs-input {
        width: 40%;
        margin: 0 5px;
      }
      .con-vs-checkbox {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0 0 0;
        padding-left: 40px;
      }
    }
  }
  .students__add-phone__button {
    &::after {
      clear: both;
    }
  }
  .student-parents__item {
    h4 {
      span {
        font-weight: normal;
      }
    }
    .student-parents__inputs {
      display: flex;
      flex-flow: row nowrap;
      gap: 15px;
    }
  }
}
</style>
