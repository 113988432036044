<template>
  <div class="surprise-item">
    <div class="surprise-item__category">
      <vs-avatar
        :color="surprise.category.color"
        :icon="surprise.category.icon"
        :title="surprise.category.name"
        :badge="surprise.stickers"
        badge-color="dark"
        v-if="surprise.category"
      ></vs-avatar>
    </div>
    <div class="surprise-item__info" @click="infoPopupActive = true">
      <div class="surprise-item__received">
        {{ $moment(surprise.received * 1000).format("DD.MM.YYYY") }}
      </div>
      <div class="surprise-item__name">
        {{ surprise.name }}
      </div>
    </div>
    <div
      class="surprise-item__giver"
      :title="surprise.giver ? surprise.giver.nickname : null"
    >
      <vs-avatar
        :src="
          surprise.giver && surprise.giver.avatar
            ? `${proxy}/static/files/${surprise.giver.avatar}`
            : undefined
        "
      ></vs-avatar>
    </div>
    <!-- <div class="surprise-item__stickers">
      <div class="surprise-item__stickers__count">
        {{ surprise.stickers }}
      </div>
    </div> -->
    <div class="surprise-item__manage" v-if="$can('manage', 'surprises')">
      <vs-button
        size="small"
        type="flat"
        icon="edit"
        color="dark"
        @click="editPopupActive = true"
      >
      </vs-button>
      <vs-button
        size="small"
        type="flat"
        icon="delete"
        color="dark"
        @click="deleteHandler"
      ></vs-button>
    </div>
    <div class="popup-info">
      <vs-popup
        class="form-popup surprise-info__popup"
        classContent="surprise__popup-content"
        :title="surprise.name"
        :active.sync="infoPopupActive"
        v-if="infoPopupActive"
      >
        <surprise-info :surprise="surprise" />
      </vs-popup>
    </div>
    <div class="popup-edit">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        :title="`Edit: ${surprise.name}`"
        :active.sync="editPopupActive"
        v-if="editPopupActive"
      >
        <surprise-form :surprise="surprise" @closePopup="closePopup" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import SurpriseForm from "./surpriseForm.vue";
import surpriseInfo from "./surpriseInfo.vue";
export default {
  components: { surpriseInfo, SurpriseForm },
  name: "surpriseItem",
  props: ["surprise"],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      infoPopupActive: false,
      editPopupActive: false,
    };
  },
  methods: {
    closePopup: function () {
      this.popupActive = false;
      this.editPopupActive = false;
    },
    deleteHandler: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete surprise",
        text: "Are you sure you want to delete the surprise?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert: function () {
      const { surprise } = this;
      this.$store.dispatch("surprises/deleteSurpriseAction", { surprise });
    },
  },
};
</script>

<style lang="scss">
.surprise-item {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-top: 2rem;
  position: relative;
  align-items: center;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 4px;
    height: 100%;
    border-radius: 0.45rem;
    background-color: #e4e6ef !important;
  }
  &:after {
    content: "";
    clear: both;
    display: block;
  }
  .surprise-item__manage {
    & > button {
      margin-left: 7px;
    }
  }
  .surprise-item__category {
    margin-left: 1rem;
    font-size: 1.2em;
    opacity: 0.6;
    .vs-avatar--text {
      font-size: 1rem !important;
    }
    .vs-avatar--count.badgeNumber {
      top: -10px;
    }
  }
  .surprise-item__info {
    margin-left: 1rem;
    cursor: pointer;
    transition: ease-out 0.2s;
    &:hover {
      color: var(--font-primary-color);
    }
    flex: 1;
    .surprise-item__received {
      color: #7e8299 !important;
      font-weight: bold;
      font-size: 0.85rem;
    }
    .surprise-item__name {
      color: #e9edf1 transparent;
      font-weight: 600;
      font-size: 1.15rem;
      // max-width: 10rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .surprise-item__giver {
    color: #b5b5c3 !important;
    font-size: 0.85rem;
  }
  .surprise-item__stickers {
    .surprise-item__stickers__count {
      padding: 10px;
      border-radius: 10px;
      background-color: #e4e6ef;
      height: auto;
      margin-right: 7px;
    }
  }
}
</style>