<template>
  <div class="collapsible">
    <div class="student-groups-header" :class="{ opened: groupOpened }">
      <div class="collapsible__button" @click="groupOpened = !groupOpened">
        <vs-icon
          icon="chevron_right"
          class="small-button collapsible__button__arrow"
          color="dark"
        ></vs-icon>
        <div>
          <vs-avatar icon="people" :color="group.color"></vs-avatar>
          <router-link
            :to="{ name: 'Group', params: { group_id: group.id } }"
            class="group-link"
          >
            <span :class="{ crossed: !group.is_active }"
              >Group: {{ group.name }}</span
            >
          </router-link>
          <span class="group-dates group-more-info">
            <span class="group-more-info__type"
              >{{ group.group_type.name }}
            </span>
            <span class="group-more-info__delimiter"> | </span>
            <span class="group-dates__start-date">
              {{ $moment(group_start * 1000).format('DD/MM/YYYY') }}
            </span>
            <span class="group-dates__delimiter">-</span>
            <span class="group-dates__end-date">
              {{ $moment(group_end * 1000).format('DD/MM/YYYY') }}
            </span>
          </span>
        </div>
      </div>
      <div class="student-groups__manage">
        <div class="student-groups__study-time">
          <vs-chip
            class="exit-date"
            v-if="group.study_time.length > 1"
            :title="studyTimeTitle"
          >
            <vs-avatar icon="start"></vs-avatar>
            + {{ group.study_time.length - 1 }}
          </vs-chip>
          <vs-chip :color="group.is_trial ? '#5b3cc4' : 'primary'" transparent>
            <vs-avatar icon="start"></vs-avatar>
            <span v-if="group.is_trial">Trial: </span>
            <span>{{
              $moment(lastStudyTime.insert * 1000).format('DD/MM/YYYY')
            }}</span>
            <span v-if="lastStudyTime.exit"
              >-
              {{
                $moment(lastStudyTime.exit * 1000).format('DD/MM/YYYY')
              }}</span
            >
          </vs-chip>
        </div>
        <div>
          <vs-button
            type="flat"
            color="primary"
            icon="edit"
            @click="$emit('editGroupHandle', group)"
            v-if="$can('manage', 'student')"
            size="small"
            >Edit</vs-button
          >
        </div>
        <div v-if="!group.is_active && $can('manage', 'student')">
          <restore-student :group_id="group.id" :student_id="student.id" />
        </div>
        <div v-if="group.is_active && $can('manage', 'student')">
          <exclude-student :group="group" :student_id="student.id" />
        </div>
        <div
          v-if="group.is_active && group.is_trial && $can('manage', 'student')"
        >
          <vs-button
            type="border"
            color="#5b3cc4"
            icon="subdirectory_arrow_left"
            @click="$emit('stayHereGroup', group)"
            size="small"
            >Stay here</vs-button
          >
        </div>
        <div
          v-if="$can('manage', 'student')"
          class="student-groups__manage__more"
        >
          <vs-dropdown vs-trigger-click>
            <vs-icon icon="more_vert"></vs-icon>
            <vs-dropdown-menu>
              <vs-dropdown-item>
                <vs-button
                  type="flat"
                  color="#c00"
                  icon="delete"
                  @click="$emit('removeStudent', group)"
                  size="small"
                  title="Remove student from group"
                  class="student-groups__manage__more__button"
                  >Remove</vs-button
                >
              </vs-dropdown-item>
              <vs-dropdown-item v-if="group.is_active">
                <vs-button
                  type="flat"
                  color="primary"
                  icon="trending_flat"
                  size="small"
                  @click="$emit('moveStudent', group)"
                  class="student-groups__manage__more__button"
                  >Move</vs-button
                >
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
    </div>
    <div class="collapsible_content" v-if="groupOpened">
      <StudentAttendance :student="student" :group="group" />
      <div class="orders" v-if="$can('manage', 'payments')">
        <OrdersWrapper
          :student="student"
          :group="group"
          orderType="groupTutor"
          v-if="group.is_tutor"
        />
        <OrdersWrapper :student="student" :group="group" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import StudentAttendance from '@/components/students/studentAttendance'
import OrdersWrapper from '@/components/students/orders/wrapper'
import ExcludeStudent from './excludeStudent.vue'
import RestoreStudent from './restoreStudent.vue'
export default {
  props: ['student', 'group'],
  title: 'SE-Student Groups',
  data() {
    return {
      groupOpened: this.group.exit_date ? false : true,
    }
  },
  computed: {
    group_start: function() {
      return this.group.start_from || this.group.schoolyear.date_start_unix
    },
    group_end: function() {
      return this.group.end_to || this.group.schoolyear.date_end_unix
    },
    lastStudyTime: function() {
      return this.group.study_time[this.group.study_time.length - 1]
    },
    studyTimeTitle: function() {
      return this.group.study_time
        .map((s, index) => {
          const startText = this.$moment(s.insert * 1000).format('DD/MM/YYYY')
          const exitText = s.exit
            ? this.$moment(s.exit * 1000).format('DD/MM/YYYY')
            : '-'
          exitText
          return `${index + 1}: ${startText} to ${exitText}`
        })
        .join('\n')
    },
  },
  components: {
    OrdersWrapper,
    StudentAttendance,
    ExcludeStudent,
    RestoreStudent,
  },
  methods: {},
}
</script>

<style lang="scss">
.student-groups-header {
  display: flex;
  flex-flow: row nowrap;
  .group-link {
    & > * {
      vertical-align: top;
    }
  }
  .group-dates {
    font-size: 0.8em;
    color: var(--thead-text-color);
    display: block;
    margin: -20px 0 0 42px;
  }
  .student-groups__manage {
    margin-left: 1rem;
    flex: 1;
    &__more {
      flex: 1;
      text-align: right;
      display: flex;
      justify-content: end;
    }
  }
  .student-groups__study-time {
    display: flex;
    flex-flow: row nowrap;
  }
}
.student-groups__manage__more__button {
  float: none !important;
}
</style>
