var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"testing-grade-grid",class:{ 'with-grade': _vm.withGrade, 'with-shift': _vm.withShift }},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"name"},[_vm._v("Student")]),(_vm.withGrade)?_c('div',{staticClass:"grade"},[_vm._v("Grade")]):_vm._e(),(_vm.withShift)?_c('div',{staticClass:"shift"},[_vm._v("Shift")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"options"})]),_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"name"},[_c('vs-icon',{attrs:{"icon":"person","size":"small"}}),_c('router-link',{staticClass:"student-link",attrs:{"to":{ name: 'Student', params: { student_id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(_vm.withGrade)?_c('div',{staticClass:"student_class"},[_vm._v(" "+_vm._s(item.student_class || '-')+" ")]):_vm._e(),(_vm.withShift)?_c('div',{staticClass:"shift"},[_vm._v(_vm._s(item.shift || '-'))]):_vm._e(),_c('div',{staticClass:"results"},_vm._l((item.testing_results),function(result,index){return _c('div',{key:index,staticClass:"result",on:{"click":function($event){return _vm.editResultHandler($event, result)}}},[_c('div',{staticClass:"color",style:({ backgroundColor: result.color || '#fff' })}),_c('div',{staticClass:"result__time"},[_vm._v(" "+_vm._s(_vm.$moment(result.test_time * 1000).format('DD-MM-YYYY'))+" "),(result.lesson_exist == 'н')?_c('div',{staticClass:"result__exist"},[_vm._v(" Не пришел ")]):_vm._e()]),_c('div',{staticClass:"result__name"},[_c('router-link',{attrs:{"to":{
              name: 'IncomeResult',
              params: { result_id: result.id },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(result.test.name || '-')+" ")])],1),_c('div',{staticClass:"result__oral-value"},[_vm._v(_vm._s(result.oral_value))]),_c('div',{staticClass:"result__written-value"},[_vm._v(_vm._s(result.written_value))]),_c('div',{staticClass:"result__lesson-comment"},[_c('vs-tooltip',{attrs:{"text":result.lesson_comment,"position":"top"}},[(result.lesson_comment)?_c('div',{staticClass:"students-test-result__comment__teacher"},[(result.lesson_comment)?_c('vs-icon',{attrs:{"icon":"comment","size":"small","color":"dark"}}):_vm._e(),_c('vs-avatar',{attrs:{"size":"16px","src":result.teachers_options.comment.teacher.options.avatar
                    ? (_vm.proxy + "/static/files/" + (result.teachers_options.comment.teacher.options.avatar))
                    : undefined}})],1):_vm._e(),_vm._v(" "+_vm._s(result.lesson_comment ? result.lesson_comment.slice(0, 25) : '-')+"... ")])],1),_c('div',{staticClass:"testing-result__comment",domProps:{"innerHTML":_vm._s(result.comment || '-')}})])}),0),_c('div',{staticClass:"options"},[_c('vs-dropdown',[_c('vs-button',{staticClass:"btn-drop",attrs:{"type":"flat","color":"dark","icon":"more_horiz"}}),_c('vs-dropdown-menu',{staticClass:"result-options-menu"},[_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"type":"flat","icon":"edit","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('editStudent', item.id)}}},[_vm._v("Edit student")]):_vm._e()],1),_c('vs-dropdown-item',[_c('vs-button',{attrs:{"type":"flat","color":"dark","icon":"visibility","size":"small"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Student',
                  params: { student_id: item.id },
                })}}},[_vm._v("View student")])],1),_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"dark","icon":"west","type":"flat","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('studentToQueue', item.id)}}},[_vm._v("To Queue")]):_vm._e()],1),_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"#5b3cc4","icon":"east","type":"flat","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('studentToTrial', item.id)}}},[_vm._v("To Trial")]):_vm._e()],1),_c('vs-dropdown-item',[(_vm.$can('manage', 'student'))?_c('vs-button',{attrs:{"color":"primary","icon":"east","type":"flat","size":"small"},on:{"click":function($event){return _vm.eventBus.$emit('studentToActive', item.id)}}},[_vm._v("To Active")]):_vm._e()],1)],1)],1)],1)])}),(_vm.editPopupActive)?_c('div',[_c('draggable-panel',{attrs:{"X":_vm.dragPositionX,"Y":_vm.dragPositionY},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit ")]},proxy:true}],null,false,2509906910)},[_c('TestingEditForm',{attrs:{"result":_vm.selectedResult},on:{"closeCommentForm":_vm.closeForm}})],1)],1):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"results"},[_c('div',{staticClass:"result"},[_c('div',{staticClass:"color"}),_c('div',{staticClass:"time"},[_vm._v("Date")]),_c('div',{staticClass:"test-name"},[_vm._v("Test name")]),_c('div',{staticClass:"oral-value"},[_vm._v("Oral part")]),_c('div',{staticClass:"written-value"},[_vm._v("Written part")]),_c('div',{staticClass:"lesson-comment"},[_vm._v("Lesson comment")]),_c('div',{staticClass:"comment"},[_vm._v("Comment")])])])}]

export { render, staticRenderFns }